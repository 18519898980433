import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import './styles.scss';

export interface IDropdownOption {
  label: string;
  value: number | string;
}

interface IDropdown {
  value: number | null;
  label?: string;
  options: IDropdownOption[] | undefined;
  onChange: Function;
  type?: number;
}
export const Dropdown = ({
  label,
  value,
  options,
  onChange,
  type,
}: IDropdown) => {
  const handleChange = (event: any) => {
    const result = event.target.value;
    onChange(result);
  };

  return (
    <FormControl
      sx={{ width: '10rem', marginRight: '2rem', marginTop: '2rem' }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      {type !== 0 && type !== 1 && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value}
          onChange={handleChange}
          className="select__general"
        >
          {options?.map(option => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {type === 0 && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value}
          onChange={handleChange}
          className="select__document"
        >
          {options?.map(option => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {type === 1 && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value}
          onChange={handleChange}
          className="select__financing"
        >
          {options?.map(option => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
