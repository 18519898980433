import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import './styles.scss';
interface IPublishModal {
  open: boolean;
  onClose: Function;
  onHandleReadyToPublish: Function;
}
interface IPublishForm {
  description: string;
}
export const PublishModal = ({
  open,
  onClose,
  onHandleReadyToPublish,
}: IPublishModal) => {
  const initialValues: IPublishForm = { description: '' };
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="formik__data">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            onHandleReadyToPublish(values);
          }}
        >
          {({ submitForm }) => (
            <>
              <DialogTitle>Confirm Publish</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Add a description
                </DialogContentText>
                <Form>
                  <Field
                    id="description"
                    name="description"
                    placeholder="Description"
                    className="value__field__desc"
                  />
                </Form>
              </DialogContent>
              <DialogActions className="buttons__actions__comment">
                <Button
                  onClick={() => onClose()}
                  variant="outlined"
                  className="button__cancel__comment"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => submitForm()}
                  variant="contained"
                  className="button__publish__comment"
                >
                  Ready to Publish
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
