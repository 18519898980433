import { BlobServiceClient } from '@azure/storage-blob';
import { useEffect, useState } from 'react';
import { AzureFolderContainer } from '../../enums/AzureFolderContainer';
import { DbOperation } from '../../enums/DbOperation';
import { IBlobMetadata } from '../../interfaces/blobMetadata';
import { IDraft } from '../../interfaces/draft';
import { useLoader } from './useLoader';
import { useRole } from './useRole';

export const useAzureStorage = () => {
  const accountName = process.env.REACT_APP_AZURE_ACCOUNT;
  const sas = process.env.REACT_APP_AZURE_SAS;

  const storageBlobEndpoint = `https://${accountName}.blob.core.windows.net`;
  const blobServiceClient = new BlobServiceClient(
    `${storageBlobEndpoint}${sas}`,
  );
  const [loading, setLoading] = useState(false);
  const { setLoader } = useLoader();
  const { userADId, userName } = useRole();

  const createDeleteDraftBlob = async (filePath: string, branchId: number) => {
    const draftContainerName = AzureFolderContainer.ContainerPublisherDrafts;
    const sourceContainerName = `branch-${branchId}`;
    const fileName = filePath.split('/').pop();
    //source blob
    const sourceContainerClientPDF =
      blobServiceClient.getContainerClient(sourceContainerName);
    const sourceBlockBlobClientPDF =
      sourceContainerClientPDF.getBlockBlobClient(`${filePath}.pdf`);
    const sourceContainerClientJSON =
      blobServiceClient.getContainerClient(sourceContainerName);
    const sourceBlockBlobClientJSON =
      sourceContainerClientJSON.getBlockBlobClient(`${filePath}.json`);

    const blobPathPDF = `branch-${branchId}/${AzureFolderContainer.FolderContracts}/${fileName}.pdf`;
    const blobPathJSON = `branch-${branchId}/${AzureFolderContainer.FolderContracts}/${fileName}.json`;
    const draftContainerClient =
      blobServiceClient.getContainerClient(draftContainerName);
    const draftBlockBlobClientPdf =
      draftContainerClient.getBlockBlobClient(blobPathPDF);
    const draftBlockBlobClientJson =
      draftContainerClient.getBlockBlobClient(blobPathJSON);
    const metadata: IBlobMetadata = {
      dboperation: DbOperation.Delete,
      publishedstatus: '0',
      readytopublish: '0',
      publishdate: '',
      publishderscription: '',
      modificationuser: userName,
      modificationuserid: userADId,
    };

    try {
      setLoading(true);
      await draftBlockBlobClientPdf.beginCopyFromURL(
        sourceBlockBlobClientPDF.url,
        {
          metadata,
        },
      );
      await draftBlockBlobClientJson.beginCopyFromURL(
        sourceBlockBlobClientJSON.url,
        {
          metadata,
        },
      );
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteDraftBlob = async (draft: IDraft) => {
    if (!draft) {
      return;
    }
    if (draft.Type === 'PDF') {
      const containerName = AzureFolderContainer.ContainerPublisherDrafts;
      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blockBlobClientPdf = containerClient.getBlockBlobClient(
        draft.DraftID + '.pdf',
      );
      const blockBlobClientJson = containerClient.getBlockBlobClient(
        draft.DraftID + '.json',
      );
      try {
        setLoading(true);
        await blockBlobClientPdf.delete({ deleteSnapshots: 'include' });
        await blockBlobClientJson.delete({ deleteSnapshots: 'include' });
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      const containerName = AzureFolderContainer.ContainerPublisherDrafts;
      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(draft.DraftID);
      try {
        setLoading(true);
        await blockBlobClient.delete({ deleteSnapshots: 'include' });
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    loading,
    createDeleteDraftBlob,
    deleteDraftBlob,
  };
};
