import { Box, Drawer } from "@mui/material";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./styles.scss";
import { useEffect, useState } from "react";

export const SideBar = ({ sideBarWidth = 340 }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Box
      component="nav"
      sx={{ width: { sm: sideBarWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        variant="permanent"
        open
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sideBarWidth,
            backgroundColor: "black",
          },
        }}
      >
        <ProSidebarProvider>
          <Sidebar>
            <Menu
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  if (level === 0) {
                    return {
                      color: disabled ? "white" : "white",
                      backgroundColor: active ? "#fff" : undefined,
                      "&:hover": {
                        backgroundColor: "#007DBB !important",
                        color: "white !important",
                        borderRadius: "8px !important",
                        fontWeight: "bold !important",
                      },
                    };
                  }
                  if (level === 1) {
                    return {
                      color: disabled ? "white" : "white",
                      backgroundColor: active ? "#fff" : undefined,
                      "&:hover": {
                        backgroundColor: "#007DBB !important",
                        color: "white !important",
                        borderRadius: "8px !important",
                        fontWeight: "bold !important",
                      },
                    };
                  }
                },
              }}
            >
              <h2 className="header__sidebar">CDX Management</h2>
              <>
                {isAuthenticated && (
                  <MenuItem component={<Link to="/scheduler" />}>
                    GTC Scheduler{" "}
                  </MenuItem>
                )}
                {!isAuthenticated && (
                  <>
                    <MenuItem component={<Link to="/dashboard" />}>
                      Publish to CDX App
                    </MenuItem>
                    <SubMenu className="submenu__item" label="GTC Management">
                      <MenuItem
                        className="sidebar__cdx__submenu"
                        component={<Link to="/taskCode" />}
                      >
                        GTC Management
                      </MenuItem>
                      <MenuItem
                        className="sidebar__cdx__submenu"
                        component={<Link to="/taskCodeIngestion" />}
                      >
                        Sync/Ingest GTC
                      </MenuItem>
                    </SubMenu>
                    <MenuItem component={<Link to="/financingOffer" />}>
                      Financing Offer
                    </MenuItem>
                    <MenuItem component={<Link to="/promotion" />}>
                      Promotion
                    </MenuItem>
                    <MenuItem component={<Link to="/document" />}>
                      PDF Management
                    </MenuItem>
                    <MenuItem component={<Link to="/configuration" />}>
                      Publisher
                    </MenuItem>
                  </>
                )}
              </>
            </Menu>
          </Sidebar>
        </ProSidebarProvider>
      </Drawer>
    </Box>
  );
};
