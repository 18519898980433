import { useEffect, useState } from 'react';
import { arsDataIngestionApi } from '../../service/arsDataIngestionApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

interface IDataIngestionBranchInfo {
  arsGTC: number;
  draftInserts: number;
  draftUpdates: number;
  draftDeletes: number;
  noChangeArsGTC: number;
}

export const useDataIngestion = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [dataIngestionInfo, setDataIngestionInfo] =
    useState<IDataIngestionBranchInfo>();
  const { setLoader } = useLoader();
  const dataIngestion = async (branchId: number) => {
    await axiosFetch({
      axiosInstance: arsDataIngestionApi,
      method: 'get',
      url: '/gtcDbDrawerSync',
      requestConfig: {
        params: { branchId },
      },
    });
  };

  useEffect(() => {
    if (response) {
      setDataIngestionInfo(response);
    }
  }, [response, error]);

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return [dataIngestionInfo, dataIngestion, loading] as const;
};
