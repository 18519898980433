import { createContext } from 'react';

interface ILoaderContext {
  loader: Boolean;
  setLoader: Function;
}

export default createContext<ILoaderContext>({
  loader: false,
  setLoader: () => {},
});
