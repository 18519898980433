import { Box, Toolbar } from '@mui/material';
import { NavBar } from '../components/navBar/NavBar';
import { SideBar } from '../components/sideBar/SideBar';
type Props = {
  children?: React.ReactNode;
};
const sideBarWidth = 260;
export const ArsLayout = ({ children }: Props) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <NavBar sideBarWidth={sideBarWidth} />

      <SideBar sideBarWidth={sideBarWidth} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
