import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { IFilter } from '../GridFilter';

interface IDropdownFilter {
  filter: IFilter;
  onChangeDropdown: Function;
  value: any;
  styles?: any;
}
export const DropdownFilter = ({
  filter,
  onChangeDropdown,
  value,
  styles,
}: IDropdownFilter) => {
  const [selectedFilter, setSelectedFilter] = useState(value);
  const onChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value);
    onChangeDropdown(event.target.value, filter.property);
  };
  return (
    <FormControl
      sx={{ width: '10rem', marginRight: '2rem', marginTop: '2rem', ...styles }}
    >
      <InputLabel id="demo-simple-select-label">{filter.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedFilter}
        label={filter.label}
        onChange={onChange}
      >
        {filter.options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
