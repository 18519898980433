import { Button } from '@mui/material';
import { AgGridReact, AgGridColumnProps } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DraftType } from '../../../enums/DraftType';
import { IDraft } from '../../../interfaces/draft';
import { IDraftsId } from '../../../interfaces/draftsId';
import { IReadyToPublishQuery } from '../../../interfaces/readyToPublishQuery';
import { IUnReadyToPublishQuery } from '../../../interfaces/unReadyToPublishQuery';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { Dropdown, IDropdownOption } from '../../components/dropdown/Dropdown';
import { useAzureStorage } from '../../hooks/useAzureStorage';
import { useDraftGtc } from '../../hooks/useDraftGtc';
import { usePublisher } from '../../hooks/usePublisher';
import { ArsLayout } from '../../layout/ArsLayout';
import { DraftActionCell } from './draftActionCell/DraftActionCell';
import { EditDraft } from './editdraft/EditDraft';
import { PublishModal } from './publishModal/PublishModal';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useRole } from '../../hooks/useRole';
import { GridFilter, IFilter } from '../../components/gridFilter/GridFilter';
import { DraftStatus } from '../../../enums/DraftStatus';
import './styles.scss';
import { DateCellRenderer } from './dateCellRenderer/DateCellRenderer';
import { toast } from 'react-toastify';
import { useDraftPromotion } from '../../hooks/useDraftPromotion';
import { useDraftFinancing } from '../../hooks/useDraftFinancing';
import { useDraftMarketingCopy } from '../../hooks/useDraftMarketingCopy';
import { GridReadyEvent, IDatasource } from 'ag-grid-community';

interface IDashboardFilter {
  branchID: number;
  DraftStatus: DraftStatus;
  Type: string;
}
export const DashboardPage = () => {
  const { drafts, getDrafts, setReadyToPublish, setUnReadyToPublish } =
    usePublisher();
  const { deleteDraftBlob } = useAzureStorage();
  const { deleteTaskCodeDraft } = useDraftGtc();
  const { deletePromotionDraft } = useDraftPromotion();
  const { deleteFinancingDraft } = useDraftFinancing();
  const { deleteMarketingCopyDraft } = useDraftMarketingCopy();

  const initialDraftsIds = {
    Promotion: [],
    TaskCode: [],
    FinancingOffer: [],
    PDF: [],
    MarketingCopy: [],
  };
  const [gridSelection, setGridSelection] = useState<any[]>();
  const [draftsID, setDraftsID] = useState<IDraftsId>(initialDraftsIds);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IDraft>();
  const [showEditDraft, setShowEditDraft] = useState(false);
  const { roleBranchOptions } = useRole();
  const [selectedTypeReady, setSelectedTypeReady] = useState<number>(0);
  const [selectedTypeUnReady, setSelectedTypeUnReady] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] = useState<IDashboardFilter>();

  const gridRef = useRef<AgGridReact>(null);

  const DraftStatusOptions = Object.keys(DraftStatus).map<IDropdownOption>(
    key => ({
      value: key,
      label: key,
    }),
  );
  const DraftTypeOptions = Object.keys(DraftType).map<IDropdownOption>(key => ({
    value: key,
    label: key,
  }));
  const filters: IFilter[] = [
    {
      label: 'Branch ID',
      options: roleBranchOptions,
      property: 'branchID',
      styles: { width: '20rem' },
    },
    {
      label: 'Record Status',
      options: DraftStatusOptions,
      property: 'DraftStatus',
    },
    {
      label: 'Type',
      options: DraftTypeOptions,
      property: 'Type',
    },
  ];

  useEffect(() => {
    if (selectedFilters) {
      handleFilterClick(selectedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedFilters({
        branchID: Number(roleBranchOptions[0]?.value),
        DraftStatus: DraftStatus.All,
        Type: DraftType.TaskCode,
      });
    }
  }, [roleBranchOptions]);

  const onSelectionChanged = (params: any) => {
    setSelectedTypeReady(0);
    setSelectedTypeUnReady(0);
    let selected: any = initialDraftsIds;
    selected = params.api.getSelectedRows();
    if (selected.length !== 0) {
      selected.forEach((val: any) => {
        if (val.DraftStatus !== 'Published') {
          if (val.ReadyToPublish === false) {
            setSelectedTypeReady(selected.length++);
          } else if (val.ReadyToPublish === true) {
            setSelectedTypeUnReady(selected.length++);
          }
        } else {
          setSelectedTypeReady(1);
          setSelectedTypeUnReady(1);
        }
      });
    }
    setGridSelection(selected);
  };

  const getDraftsIds = () => {
    const draftsIdsGroup: any = { ...initialDraftsIds };
    if (!gridSelection) {
      return draftsID;
    }
    gridSelection.forEach((val: { Type: string | number; DraftID: any }) => {
      draftsIdsGroup[val.Type].push({ ID: val.DraftID });
    });
    return draftsIdsGroup;
  };

  const handleClickReady = () => {
    setOpenPublishModal(true);
  };
  const handleClosePublishModal = () => {
    setOpenPublishModal(false);
    setDraftsID(initialDraftsIds);
  };
  const handleClickUnReady = async () => {
    await setUnReadyToPublish({ Drafts: getDraftsIds() });
    toast.success('Drafts not scheduled to be published');
    setDraftsID(initialDraftsIds);

    gridRef.current!.api.refreshInfiniteCache();
  };
  const onClickCellReady = (rowData: IDraft) => {
    setDraftsID(drafts => {
      let draftsIds: IDraftsId = { ...drafts };
      draftsIds[rowData.Type].push({ ID: rowData.DraftID });
      return drafts;
    });
    setOpenPublishModal(true);
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      getRows: async params => {
        if (
          !params.filterModel.Branch?.filter ||
          !params.filterModel.DraftStatus?.filter ||
          !params.filterModel.Type?.filter
        ) {
          params.failCallback();
          return;
        }
        const status =
          params.filterModel.DraftStatus?.filter === DraftStatus.All
            ? undefined
            : params.filterModel.DraftStatus?.filter;
        const { drafts, total } = await getDrafts({
          branchId: params.filterModel.Branch?.filter,
          draftStatus: status,
          draftType: params.filterModel.Type?.filter,
          startRow: params.startRow,
          endRow: params.endRow,
        });

        params.successCallback(drafts, total);
      },
    };
    params.api.setDatasource(dataSource);
  }, []);
  const handleFilterClick = ({
    branchID,
    DraftStatus,
    Type,
  }: IDashboardFilter) => {
    if (!gridRef.current!.api) {
      return;
    }
    const filterModel = gridRef.current!.api.getFilterModel();
    filterModel.Branch = {
      filterType: 'text',
      type: 'equals',
      filter: branchID,
    };
    filterModel.DraftStatus = {
      filterType: 'text',
      type: 'contains',
      filter: DraftStatus,
    };
    filterModel.Type = {
      filterType: 'text',
      type: 'contains',
      filter: Type,
    };
    gridRef.current!.api.setFilterModel(filterModel);
  };

  const onClickCellRevert = async (rowData: IDraft) => {
    let draftsIds: IDraftsId = { ...initialDraftsIds };
    draftsIds[rowData.Type] = [{ ID: rowData.DraftID }];
    const unReadyToPublishQuery: IUnReadyToPublishQuery = {
      Drafts: draftsIds,
    };
    await setUnReadyToPublish(unReadyToPublishQuery);
    toast.success('Draft not scheduled to be published');
    gridRef.current!.api.refreshInfiniteCache();

    setDraftsID(initialDraftsIds);
  };
  const onClickCellEdit = (rowData: IDraft) => {
    setSelectedRow(rowData);
    setShowEditDraft(true);
  };
  const onClickCellDelete = (rowData: IDraft) => {
    setSelectedRow(rowData);
    setOpenDeleteDialog(true);
  };
  const handleDelete = async () => {
    if (!selectedRow?.DraftID) {
      return;
    }
    switch (selectedRow.Type) {
      case DraftType.TaskCode:
        await deleteTaskCodeDraft(selectedRow.DraftID);
        break;
      case DraftType.Promotion:
        await deletePromotionDraft(selectedRow.DraftID);
        break;
      case DraftType.FinancingOffer:
        await deleteFinancingDraft(selectedRow.DraftID);
        break;
      case DraftType.PDF:
        await deleteDraftBlob(selectedRow);
        break;
      case DraftType.MarketingCopy:
        await deleteMarketingCopyDraft(selectedRow.DraftID);
        break;
    }
    toast.success('Draft deleted');

    gridRef.current!.api.refreshInfiniteCache();
    setSelectedRow(undefined);
    setOpenDeleteDialog(false);
  };
  const handleReadyToPublish = async ({
    description,
  }: {
    description: string;
  }) => {
    if (!selectedFilters) {
      return;
    }
    const readyToPublishQuery: IReadyToPublishQuery = {
      Description: description,
      Drafts: getDraftsIds(),
      BranchId: selectedFilters.branchID,
    };
    await setReadyToPublish(readyToPublishQuery);
    toast.success('Draft scheduled to be published');
    gridRef.current!.api.refreshInfiniteCache();

    setDraftsID(initialDraftsIds);
    setOpenPublishModal(false);
  };

  const handleCellRenderer = (data: any) => {
    return DraftActionCell(
      data,
      onClickCellReady,
      onClickCellRevert,
      onClickCellEdit,
      onClickCellDelete,
    );
  };
  const handleDateCellRenderer = (data: any) => {
    return DateCellRenderer(data);
  };

  const columnsDefinition: AgGridColumnProps[] = [
    {
      initialWidth: 70,
      checkboxSelection: true,
    },
    {
      field: 'Type',
      headerName: 'Type',
      resizable: true,
      initialWidth: 200,
      filter: true,
    },
    {
      field: 'DraftDescription',
      headerName: 'Description',
      resizable: true,
      initialWidth: 800,
    },
    {
      field: 'DBOperation',
      headerName: 'Update Type',
      resizable: true,
      initialWidth: 130,
      filter: true,
    },
    {
      field: 'DraftStatus',
      headerName: 'Record Status',
      resizable: true,
      initialWidth: 120,
      filter: true,
    },
    {
      headerName: 'Status Date',
      initialWidth: 220,
      resizable: true,
      cellRenderer: handleDateCellRenderer,
      editable: false,
      colId: 'date',
    },
    {
      field: 'PublishDerscription',
      headerName: 'Publish Description',
      resizable: true,
      filter: true,
    },
    {
      field: 'ModificationUser',
      headerName: 'Modification User',
      resizable: true,
      initialWidth: 300,
      filter: true,
    },
    {
      headerName: 'Edit/Delete',
      width: 200,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
    {
      colId: 'Branch',
      maxWidth: 0,
      minWidth: 0,
      width: 0,
      filter: true,
      hide: true,
    },
  ];
  const [columnDefs] = useState(columnsDefinition);

  return (
    <ArsLayout>
      {(!showEditDraft || !selectedRow) && (
        <div className="grid__box">
          <div>
            <h1>CDX Updates</h1>
          </div>
          {selectedFilters && (
            <div className="dropdown__branch">
              <GridFilter
                filters={filters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </div>
          )}
          <div className="buttons__ready">
            <Button
              variant="outlined"
              className="button__ready__publish"
              onClick={handleClickReady}
              startIcon={<CloudUploadIcon />}
              disabled={
                selectedTypeReady === 0 ||
                (selectedTypeReady > 0 && selectedTypeUnReady > 0)
              }
            >
              Ready to Publish
            </Button>
            <Button
              variant="outlined"
              onClick={handleClickUnReady}
              className="button__ready__unpublish"
              startIcon={<SettingsBackupRestoreIcon />}
              disabled={
                selectedTypeUnReady === 0 ||
                (selectedTypeReady > 0 && selectedTypeUnReady > 0)
              }
            >
              UnReady to Publish
            </Button>
          </div>
          <div className="ag-theme-alpine grid__data">
            <AgGridReact
              detailRowHeight={800}
              columnDefs={columnDefs}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              onSelectionChanged={onSelectionChanged}
              ref={gridRef}
              rowModelType="infinite"
              onGridReady={onGridReady}
            />
          </div>
        </div>
      )}

      {showEditDraft && selectedRow && (
        <EditDraft
          draft={selectedRow}
          onCloseCreateEdit={() => {
            setShowEditDraft(false);
            gridRef.current!.api.refreshInfiniteCache();
          }}
        />
      )}

      <PublishModal
        open={openPublishModal}
        onHandleReadyToPublish={handleReadyToPublish}
        onClose={handleClosePublishModal}
      />
      <DeleteConfirmation
        open={openDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </ArsLayout>
  );
};
