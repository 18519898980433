import { useEffect, useState } from 'react';
import { ModalMode } from '../../../config/enums';
import { ITaskCode } from '../../../interfaces/taskCode';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ArsLayout } from '../../layout/ArsLayout';
import { TaskCodeGrid } from './taskCodeGrid/TaskCodeGrid';
import { Dropdown } from '../../components/dropdown/Dropdown';
import './styles.scss';
import { useDraftGtc } from '../../hooks/useDraftGtc';
import { DbOperation } from '../../../enums/DbOperation';
import { useRole } from '../../hooks/useRole';
import { toast } from 'react-toastify';
import { EditMarketing } from './editMarketing/EditMarketing';

export const TaskCodePage = () => {
  const { updateTaskCodeDraft, createTaskCodeDraft, getDraftByTaskCodeID } =
    useDraftGtc();
  const { userADId, userName } = useRole();
  const [openCreateEdit, setOpenCreateEdit] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ITaskCode>();
  const [mode, setMode] = useState<ModalMode>(ModalMode.ReadOnly);
  const { roleBranchOptions } = useRole();
  const [selectedBranch, setSelectedBranch] = useState<number>(0);

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);

  const onClickView = (rowData: ITaskCode) => {
    setMode(ModalMode.ReadOnly);
    setSelectedRow(rowData);
    setOpenCreateEdit(true);
  };
  const onClickEditMarketing = (rowData: ITaskCode) => {
    setMode(ModalMode.Update);
    setSelectedRow(rowData);
    setOpenCreateEdit(true);
  };
  const onClickDelete = (rowData: ITaskCode) => {
    setSelectedRow(rowData);
    setOpenDeleteDialog(true);
  };
  const handleTaskCodeFormClose = () => {
    setSelectedRow(undefined);
    setOpenCreateEdit(false);
  };
  const handleDelete = async () => {
    if (!selectedRow?.ID) {
      return;
    }
    const taskCode = {
      ...selectedRow,
      DBOperation: DbOperation.Delete,
      ModificationUser: userName,
      ModificationUserId: userADId,
    };
    const { recordset } = await getDraftByTaskCodeID(selectedRow?.ID);

    if (recordset.length === 0) {
      await createTaskCodeDraft(taskCode);
    } else {
      await updateTaskCodeDraft(taskCode);
    }
    toast.success('GTC removal Draft created');
    // await deleteTaskCode(selectedRow);
    setSelectedRow(undefined);
    setOpenDeleteDialog(false);
  };
  const handleBranchSelected = (branch: number) => {
    setSelectedBranch(branch);
  };

  return (
    <ArsLayout>
      {openCreateEdit ? (
        <EditMarketing
          mode={mode}
          rowData={selectedRow}
          onCloseCreateEdit={handleTaskCodeFormClose}
        ></EditMarketing>
      ) : (
        <>
          <div>
            <h1>GTC & Components</h1>
          </div>
          <div className="grid__header__gtc">
            <Dropdown
              onChange={handleBranchSelected}
              options={roleBranchOptions}
              value={selectedBranch}
              label="Branch ID"
            />
          </div>
          <TaskCodeGrid
            selectedBranch={selectedBranch}
            onClickView={onClickView}
            onClickEdit={onClickEditMarketing}
            onClickDelete={onClickDelete}
          />
        </>
      )}

      <DeleteConfirmation
        open={openDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </ArsLayout>
  );
};
