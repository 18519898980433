import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalMode } from '../../../../config/enums';
import { IPromotion } from '../../../../interfaces/promotion';
import { useEffect, useState } from 'react';
import { handleNumericChange } from '../../../../helpers/helper';
import { Button } from '@mui/material';
import './styles.scss';
import { IPromotionDraft } from '../../../../interfaces/promotionDraft';
import { useDraftPromotion } from '../../../hooks/useDraftPromotion';
import { useRole } from '../../../hooks/useRole';
import { toast } from 'react-toastify';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { DbOperation } from '../../../../enums/DbOperation';

interface IPromotionForm {
  mode: ModalMode;
  rowData?: IPromotion;
  onCloseCreateEdit: Function;
  draftID?: string;
}

const initialPromotion: IPromotion = {
  additional: null,
  branchNumber: '',
  id: 0,
  name: null,
  percent: null,
  number: null,
};

const SignupSchema = Yup.object().shape({
  id: Yup.number(),
  additional: Yup.number().nullable(),
  branchNumber: Yup.string().test(
    'not array',
    '${path} incorrect array syntax',
    value => {
      try {
        if (!value) {
          return false;
        }
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    },
  ),
  name: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  percent: Yup.number().nullable(),
  number: Yup.string().min(2, 'Too Short').max(1000, 'Too Long'),
});

export const PromotionForm = ({
  rowData = initialPromotion,
  mode,
  onCloseCreateEdit,
  draftID,
}: IPromotionForm) => {
  const {
    promotionDrafts,
    getDraftByPromotionID,
    getPromotionDraftByDraftID,
    createPromotionDraft,
    updatePromotionDraft,
  } = useDraftPromotion();
  const { userName, userADId, roleBranchOptions } = useRole();

  const [promotionDraft, setPromotionDraft] = useState<IPromotionDraft>();

  let title;
  if (mode === ModalMode.Create) title = 'Create Promotion';
  if (mode === ModalMode.Update) title = 'Edit Promotion';

  //initializer
  useEffect(() => {
    fetchDraft();
  }, []);

  const fetchDraft = async () => {
    // Creating new Draft from Promotion
    if (mode === ModalMode.Create) {
      setPromotionDraft({
        ...initialPromotion,
        DBOperation: DbOperation.Insert,
      });
    }
    // Updating fom Promotion
    if (mode === ModalMode.Update && rowData && !draftID) {
      setPromotionDraft({ ...rowData, DBOperation: DbOperation.Update });
    }
    // Updating from CDX Updates
    if (draftID) {
      const { recordset } = await getPromotionDraftByDraftID(draftID);
      setPromotionDraft(recordset[0]);
    }
  };
  const onSubmit = async (value: IPromotionDraft) => {
    // Creating new Draft from Promotion
    if (mode === ModalMode.Create) {
      const promotionDraft: IPromotionDraft = {
        ...value,
        ModificationUser: userName,
        ModificationUserId: userADId,
      };
      await createPromotionDraft(promotionDraft);
      toast.success('Promotion draft created');
    }

    // Updating fom Promotion
    if (mode === ModalMode.Update && rowData && !draftID) {
      const promotionDraft: IPromotionDraft = {
        ...value,
        ModificationUser: userName,
        ModificationUserId: userADId,
      };
      const { recordset } = await getDraftByPromotionID(rowData.id.toString());
      if (recordset[0]) {
        promotionDraft.DraftID = recordset[0].DraftID;
        await updatePromotionDraft(promotionDraft);
      } else {
        await createPromotionDraft(promotionDraft);
      }
      toast.success('Promotion draft updated');
    }
    // Updating from CDX Updates
    if (draftID) {
      const promotionDraft: IPromotionDraft = {
        ...value,
        ModificationUser: userName,
        ModificationUserId: userADId,
      };
      promotionDraft.DraftID = draftID;
      await updatePromotionDraft(promotionDraft);
      toast.success('Promotion draft updated');
    }
    onCloseCreateEdit();
  };

  const onClickClose = (event: any) => {
    event.preventDefault();
    onCloseCreateEdit();
  };

  const handleBranchChanged = (branch: number, setValues: Function) => {
    setValues((prev: IPromotion) => ({
      ...prev,
      branchNumber: `["${branch}"]`,
    }));
  };

  return (
    <>
      <div>
        <h1>{title}</h1>
      </div>
      {promotionDraft && (
        <Formik
          initialValues={promotionDraft}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
        >
          {({ values, errors, touched, setValues, setFieldValue }) => (
            <Form>
              <div className="form__promo">
                <div className="form__promo__fields">
                  {ModalMode.Update === mode && (
                    <div className="field__value">
                      <label>ID</label>
                      <Field
                        id="id"
                        className="value__field__promo"
                        name="id"
                        placeholder="ID"
                        value={values.id ?? ''}
                        disabled
                      />
                    </div>
                  )}
                  <div className="field__value__promo">
                    <label>Name</label>

                    <Field
                      id="name"
                      className="value__field__promo"
                      name="name"
                      placeholder="Name"
                      value={values.name ?? ''}
                    />
                    <small>
                      {touched.name && errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__value__promo">
                    <label>Additional</label>
                    <Field
                      id="additional"
                      className="value__field__promo"
                      name="additional"
                      placeholder="Additional"
                      value={values.additional ?? ''}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />

                    <small>
                      {touched.additional && errors.additional && (
                        <div className="error">{errors.additional}</div>
                      )}
                    </small>
                  </div>

                  <div className="field__value__promo">
                    <label>Branch Number</label>
                    {mode === ModalMode.Create ? (
                      <Dropdown
                        onChange={(event: any) =>
                          handleBranchChanged(event, setValues)
                        }
                        options={roleBranchOptions}
                        value={Number(
                          values.branchNumber.replace(/[\[\]\"]/g, ''),
                        )}
                        label="Branch ID"
                      />
                    ) : (
                      <label>{values.branchNumber}</label>
                    )}
                  </div>

                  <div className="field__value__promo">
                    <label>Percent</label>

                    <Field
                      id="percent"
                      className="value__field__promo"
                      name="percent"
                      placeholder="Percent"
                      value={values.percent ?? ''}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.percent && errors.percent && (
                        <div className="error">{errors.percent}</div>
                      )}
                    </small>
                  </div>

                  <div className="field__value__promo">
                    <label>Number</label>

                    <Field
                      id="number"
                      className="value__field__promo"
                      name="number"
                      placeholder="Number"
                      value={values.number ?? ''}
                    />
                    <small>
                      {touched.name && errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="buttons__actions">
                <Button
                  onClick={onClickClose}
                  variant="outlined"
                  className="button__cancel"
                >
                  Cancel/Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="button__save__promo"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
