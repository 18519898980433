import { Navigate, Route, Routes } from "react-router-dom";
import { TaskCodePage } from "../pages/taskCodePage/TaskCodePage";
import { ProfilePage } from "../pages/profilePage/ProfilePage";
import { ConfigurationPage } from "../pages/configurationPage/ConfigurationPage";
import { FinancingOfferPage } from "../pages/financingOfferPage/FinancingOfferPage";
import { PromotionPage } from "../pages/promotionPage/PromotionPage";
import { DashboardPage } from "../pages/dashboardPage/DashboardPage";
import { DocumentPage } from "../pages/documentPage/DocumentPage";
import { IngestTaskCodePage } from "../pages/ingestTaskCodePage/IngestTaskCodePage";
import { SchedulerGTC } from "../pages/schedulerGTC/SchedulerGTC";
import { LoginPage } from "../pages/loginPage/loginPage";
import { useEffect, useState } from "react";

export const ArsRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/scheduler" element={<SchedulerGTC />} />
          <Route path="/login" element={<LoginPage onLogin={() => {}} />} />
          <Route path="/*" element={<Navigate to="/scheduler" />} />
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/taskCode" element={<TaskCodePage />} />
          <Route path="/taskCodeIngestion" element={<IngestTaskCodePage />} />
          <Route path="/financingOffer" element={<FinancingOfferPage />} />
          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/document" element={<DocumentPage />} />
          <Route path="/configuration" element={<ConfigurationPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        </>
      )}
    </Routes>
  );
};
