import { IDropdownOption } from '../dropdown/Dropdown';
import { DropdownFilter } from './dropdownFilter/DropdownFilter';
import './styles.scss';

interface IGridFilter {
  filters: IFilter[];
  selectedFilters: any;
  setSelectedFilters: Function;
}
export interface IFilter {
  label: string;
  property: string;
  options: IDropdownOption[];
  styles?: any;
}
export const GridFilter = ({
  filters,
  selectedFilters,
  setSelectedFilters,
}: IGridFilter) => {
  const onDropdownChange = (value: string, property: string) => {
    setSelectedFilters((prevSelecteFilters: any) => {
      const selected = { ...prevSelecteFilters };
      selected[property] = value;
      console.log(selected);

      return selected;
    });
  };

  return (
    <>
      {filters.map(filter => (
        <DropdownFilter
          key={filter.property}
          filter={filter}
          onChangeDropdown={onDropdownChange}
          value={selectedFilters[filter.property]}
          styles={filter.styles}
        />
      ))}
    </>
  );
};
