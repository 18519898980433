import { Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';
import LoaderContext from '../context/LoaderContext';
type Props = {
  children?: React.ReactNode;
};
export const Loader = ({ children }: Props) => {
  const [loader, setLoader] = useState(false);

  return (
    <LoaderContext.Provider value={{ loader, setLoader }}>
      {children}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoaderContext.Provider>
  );
};
