import { ArsLayout } from '../../layout/ArsLayout';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { useEffect, useState } from 'react';
import { usePublisher } from '../../hooks/usePublisher';
import './styles.scss';
import { useRole } from '../../hooks/useRole';

export const ConfigurationPage = () => {
  const { publishInfo, loading, setPublish } = usePublisher();

  const [selectedBranch, setSelectedBranch] = useState(0);
  const { roleBranchOptions } = useRole();

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);
  const handleBranchSelected = (branch: number) => {
    setSelectedBranch(branch);
  };
  const hadlePublish = () => {
    setPublish({ BranchId: selectedBranch });
  };

  return (
    <ArsLayout>
      <div className="card">
        <div className="detail">
          <h2>Publisher</h2>
        </div>
        <div className="publisher__data">
          <Dropdown
            onChange={handleBranchSelected}
            options={roleBranchOptions}
            value={selectedBranch}
            label="Branch ID"
          />
          <div className="publish__branch">
            <Button
              onClick={hadlePublish}
              variant="contained"
              className="button__publish"
            >
              Publish
            </Button>
          </div>
        </div>
        {publishInfo && (
          <div className="detail">
            <h2>Publish Summary</h2>
            <p>Number of PDF drafts published: {publishInfo.Blob / 2}</p>
            <p>Number of TaskCode drafts published: {publishInfo.TaskCode}</p>
            <p>
              Number of Financing Offers published:{publishInfo.FinancingOffer}
            </p>
            <p>
              Number of Promotions drafts published: {publishInfo.Promotion}
            </p>
            <p>
              Number of Marketing Copy drafts published:{' '}
              {publishInfo.MarketingCopy}
            </p>
          </div>
        )}
      </div>
    </ArsLayout>
  );
};
