import { Field, Form, Formik } from 'formik';
import { ModalMode } from '../../../../config/enums';
import * as Yup from 'yup';
import { SetStateAction, useEffect, useState } from 'react';
import { uploadFile } from '../../../../service/azureBlobStorage';
import { IComponent, ITaskCode } from '../../../../interfaces/taskCode';
import './styles.scss';
import { Button, IconButton, imageListItemBarClasses } from '@mui/material';
import { IMarketingCopy } from '../../../../interfaces/marketingCopy';
import { IMarketingCopyDraft } from '../../../../interfaces/marketingCopyDraft';
import { toast } from 'react-toastify';
import { useRole } from '../../../hooks/useRole';
import { useDraftMarketingCopy } from '../../../hooks/useDraftMarketingCopy';
import { DbOperation } from '../../../../enums/DbOperation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

interface IMarketingCopyForm {
  rowData?: ITaskCode;
  onCloseCreateEdit: Function;
  draftID?: string;
}

const componentSchema = Yup.object().shape({
  MarketingCopy: Yup.string().min(2, 'Too Short').max(300, 'Too Long'),
});
export const MarketingCopyForm = ({
  rowData,
  onCloseCreateEdit,
  draftID,
}: IMarketingCopyForm) => {
  const {
    getDraftByMarketingCopyID,
    createMarketingDraft,
    updateMarketingDraft,
  } = useDraftMarketingCopy();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState('');
  const [title, setTitle] = useState('');
  const { userName, userADId } = useRole();
  const [modeMarketing, setModeMarketing] = useState('');
  const [marketingDraft, setMarketingDraft] = useState<any>();
  const [marketingEdit, setMarketingEdit] = useState<IMarketingCopyDraft>();
  const [componentsArray, setComponentsArray] = useState<any>();

  useEffect(() => {
    // create the preview
    if (!image) {
      setPreview('');
      return;
    }
    validateBlobImage();
  }, [image]);

  useEffect(() => {
    validate();
  }, []);

  const validate = async () => {
    if (draftID) {
      fetchDraft(ModalMode.Update);
      setModeMarketing(ModalMode.Update);
      setTitle('Edit');
    }
  };

  const fetchDraft = async (mode: string) => {
    if (draftID) {
      const { recordset } = await getDraftByMarketingCopyID(draftID, 'draft');
      setMarketingEdit(recordset[0]);
      const components = JSON.parse(recordset[0].Components);
      setComponentsArray(components);
      setPreview(components[components.length - 1]?.Picture.split(','));
      setMarketingDraft(components[components.length - 1]);
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const onClickClose = (event: any) => {
    event.preventDefault();
    onCloseCreateEdit();
  };

  const validateBlobImage = async () => {
    const response = await uploadFile(image);
    setPreview(response);
  };
  const updateComponents = (component: any) => {
    const indiceElemento = componentsArray.findIndex(
      (compo: { UniqueID: any }) => compo.UniqueID == component.UniqueID,
    );
    componentsArray[indiceElemento] = component;
    return componentsArray;
  };

  const onSubmit = async (value: any) => {
    const component = { ...value };
    const componentUpdate = updateComponents(component);
    component.Picture = preview.toString();
    if (draftID) {
      const marketingDraft: any = {
        Components: JSON.stringify(componentUpdate),
        PublishID: 0,
        DBOperation: DbOperation.Update,
        ModificationUser: userName,
        ModificationUserId: userADId,
        ComponentName: component.MarketingName,
        BranchID: marketingEdit?.BranchID,
        TaskCodeID: marketingEdit?.TaskCodeID,
      };
      marketingDraft.DraftID = draftID;
      console.log(marketingDraft);
      await updateMarketingDraft(marketingDraft);
      toast.success('Marketing Copy update draft updated');
    }
    onCloseCreateEdit();
  };

  return (
    <>
      <div>
        <h1>Edit Component Draft</h1>
      </div>

      {marketingDraft && (
        <Formik
          initialValues={marketingDraft}
          onSubmit={onSubmit}
          validationSchema={componentSchema}
        >
          {({ values, errors, touched }) => (
            <Form className="form__marketing">
              <div className="table__label__compo">
                <div className="field__label">
                  <label>Marketing Name</label>
                  <span>
                    <Field
                      className="value__field__component__large"
                      id="MarketingName"
                      name="MarketingName"
                      placeholder="MarketingName"
                    />
                    <small></small>
                  </span>
                </div>
                <div className="field__label">
                  <label>Price</label>
                  <span>
                    <Field
                      className="value__field__component"
                      id="Price"
                      name="Price"
                      placeholder="Price"
                      disabled
                    />
                    <small></small>
                  </span>
                </div>
                <div className="form__field">
                  <label>Picture</label>
                  <div className="picture__section">
                    <span>
                      <img
                        className="card-img"
                        src={preview ? preview : marketingDraft.Picture}
                        alt=""
                      />
                    </span>
                  </div>
                  <span className="drop__image__document">
                    <input
                      className="input__file"
                      type="file"
                      id="images"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="images">
                      <AddCircleOutlineIcon className="input__file__icon" />
                    </label>
                    <label className="title__add__image"> Add Image</label>
                  </span>
                </div>
              </div>
              <div className="buttons__marketing">
                <div className="buttons__actions__close">
                  <Button
                    onClick={onClickClose}
                    variant="outlined"
                    className="button__cancel__component"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="button__save__component"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
