import { ArsLayout } from '../../layout/ArsLayout';
import { ISyncDbResponse } from '../../../interfaces/syncDbResponse';
import { useDataIngestion } from '../../hooks/useDataIngestion';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { useEffect, useState } from 'react';
import { useRole } from '../../hooks/useRole';
import './styles.scss';

export const IngestTaskCodePage = () => {
  const noSyncInfo: ISyncDbResponse = {
    lastSync: undefined,
    status: false,
    branches: [],
  };
  const [dataIngestionInfo, dataIngestion, loading] = useDataIngestion();
  const [selectedBranch, setSelectedBranch] = useState(0);
  const { roleBranchOptions } = useRole();

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);

  const handleBranchSelected = (branch: number) => {
    setSelectedBranch(branch);
  };
  const handleDataIngestion = async () => {
    dataIngestion(selectedBranch);
  };
  return (
    <ArsLayout>
      <div>
        <div className="card">
          <div className="detail">
            <h2>Sync/Ingest GTC</h2>
          </div>
          <div className="publisher__data">
            <Dropdown
              onChange={handleBranchSelected}
              options={roleBranchOptions}
              value={selectedBranch}
              label="Branch ID"
            />
            <div className="ingest__grid">
              <Button
                onClick={handleDataIngestion}
                disabled={loading}
                variant="outlined"
                className="button__ingest"
              >
                Ingest Data
              </Button>
            </div>
          </div>
          {dataIngestionInfo && !loading && (
            <div className="detail">
              <h3>Ingestion Summary</h3>
              <p>Records from Connect: {dataIngestionInfo?.arsGTC}</p>
              <p>
                No Change Records:
                {dataIngestionInfo?.noChangeArsGTC}
              </p>
              <h3>Created Records</h3>
              <p>Delete draft: {dataIngestionInfo?.draftDeletes}</p>
              <p>Insert draft: {dataIngestionInfo?.draftInserts}</p>
              <p>Update draft: {dataIngestionInfo?.draftUpdates}</p>
            </div>
          )}
        </div>
      </div>
    </ArsLayout>
  );
};
