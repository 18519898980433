import { useEffect, useState } from 'react';
import { IFinancingOfferDraft } from '../../interfaces/financingDraft';
import { IFinancingOffer } from '../../interfaces/financingOffer';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const useDraftFinancing = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [financingDrafts, setFinancingDrafts] = useState<IFinancingOffer[]>([]);
  const { setLoader } = useLoader();
  const getDraftByFinancingID = async (id: string) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Draft/FinancingOffer',
      requestConfig: {
        params: { id },
      },
    });
  };
  useEffect(() => {
    if (response?.recordset) {
      setFinancingDrafts(response?.recordset);
    }
  }, [response]);

  const getFinancingDraftByDraftID = async (draftId: string) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Draft/FinancingOffer',
      requestConfig: {
        params: { draftid: draftId },
      },
    });
  };
  useEffect(() => {
    if (response?.recordset) {
      setFinancingDrafts(response?.recordset);
    }
  }, [response]);

  const createFinancingDraft = async (financing: IFinancingOffer) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Draft/FinancingOffer',
      requestConfig: {
        data: financing,
      },
    });
  };

  const updateFinancingDraft = async (financing: IFinancingOfferDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'put',
      url: 'Draft/FinancingOffer',
      requestConfig: {
        data: financing,
        params: { draftid: financing.DraftID },
      },
    });
  };

  const deleteFinancingDraft = async (id: string) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'delete',
      url: 'Draft/FinancingOffer',
      requestConfig: {
        params: { draftid: id },
      },
    });
  };

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    financingDrafts,
    loading,
    getDraftByFinancingID,
    createFinancingDraft,
    updateFinancingDraft,
    deleteFinancingDraft,
    getFinancingDraftByDraftID,
  };
};
