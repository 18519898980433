import { useAxios } from './useAxios';
import { useEffect, useState } from 'react';
import { IDraft } from '../../interfaces/draft';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { IReadyToPublishQuery } from '../../interfaces/readyToPublishQuery';
import { IUnReadyToPublishQuery } from '../../interfaces/unReadyToPublishQuery';
import { IPublishQuery } from '../../interfaces/publishQuery';
import { IDraftsPublishInfo } from '../../interfaces/draftPublishInfo';
import { useLoader } from './useLoader';
import { useRole } from './useRole';
import { DraftStatus } from '../../enums/DraftStatus';
import { DraftType } from '../../enums/DraftType';

export const usePublisher = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [drafts, setDrafts] = useState<IDraft[]>([]);
  const [publishInfo, setPublishInfo] = useState<IDraftsPublishInfo>();
  const { setLoader } = useLoader();
  const { userADId, userName } = useRole();

  interface IGetDrafts {
    branchId?: number;
    draftStatus?: DraftStatus;
    draftType?: DraftType;
    startRow: number;
    endRow: number;
  }
  const getDrafts = async ({
    branchId,
    draftStatus,
    draftType,
    startRow,
    endRow,
  }: IGetDrafts) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Publisher/Drafts',
      requestConfig: {
        params: { branchId, draftStatus, draftType, startRow, endRow },
      },
    });
  };

  const setPublish = async (publishQuery: IPublishQuery) => {
    const { draftsPublished } = await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Publisher/Publish',
      requestConfig: {
        params: publishQuery,
      },
    });
    setPublishInfo(draftsPublished);
  };

  const setReadyToPublish = async (
    readyToPublishQuery: IReadyToPublishQuery,
  ) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Publisher/ReadyToPublish',
      requestConfig: {
        data: {
          ...readyToPublishQuery,
          ModificationUser: userName,
          ModificationUserID: userADId,
        },
      },
    });
  };

  const setUnReadyToPublish = async (
    unReadyToPublishQuery: IUnReadyToPublishQuery,
  ) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Publisher/UnReadyToPublish',
      requestConfig: {
        data: unReadyToPublishQuery,
      },
    });
  };

  useEffect(() => {
    if (response && response.drafts !== undefined) {
      setDrafts(response.drafts);
    }
  }, [response]);

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    drafts,
    publishInfo,
    error,
    loading,
    getDrafts,
    setReadyToPublish,
    setUnReadyToPublish,
    setPublish,
  } as const;
};
