export const jsonMap = {
  accentColorofSelectedDesigns: 'accentColorofSelectedDesigns',
  accentColorofUnselectedDesigns: 'accentColorofUnselectedDesigns',
  branchIdsSupported: 'branchIdsSupported',
  documentOrder: 'documentOrder',
  EHLFlag: 'EHLFlag',
  id: 'id',
  line: 'line',
  maxCountOfPreExistingDamage: 'maxCountOfPreExistingDamage',
  maxCountOfSelectedDesignRooms: 'maxCountOfSelectedDesignRooms',
  maxCountOfSelectedDesigns: 'maxCountOfSelectedDesigns',
  maxCountOfUnselectedDesignRooms: 'maxCountOfUnselectedDesignRooms',
  maxCountOfUnselectedDesigns: 'maxCountOfUnselectedDesigns',
  maxCountWorkPerformed: 'maxCountWorkPerformed',
  maxCountWorkRecommended: 'maxCountWorkRecommended',
  maxHeightOfInstallationWorkItems: 'maxHeightOfInstallationWorkItems',
  maxHeightofTaskCode: 'maxHeightofTaskCode',
  maxHeightofUnselectedDesigns: 'maxHeightofUnselectedDesigns',
  maxWidthofTaskCode: 'maxWidthofTaskCode',
  maxWidthOfInstallationWorkItems: 'maxWidthOfInstallationWorkItems',
  maxWidthofUnselectedTaskCode: 'maxWidthofUnselectedTaskCode',
  name: 'name',
  pages: 'pages',
  pagesWithSignatures: 'pagesWithSignatures',
  required: 'required',
  type: 'type',
};
