import { AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import { GridActionCell } from '../../../components/GridActionCell';
import { IFinancingOffer } from '../../../../interfaces/financingOffer';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface FinancingOfferGridInferface {
  financingOffers: IFinancingOffer[];
  onClickEdit: Function;
  onClickDelete: Function;
}

export const FinancingOfferGrid = ({
  financingOffers,
  onClickEdit,
  onClickDelete,
}: FinancingOfferGridInferface) => {
  const handleCellRenderer = (data: any) => {
    return GridActionCell(
      data,
      onClickEdit,
      onClickDelete,
      onClickEdit,
      'promo',
    );
  };
  const columnsDefinition: any[] = [
    { field: 'ID', headerName: 'ID', resizable: true },
    { field: 'LoanCode', headerName: 'Loan Code', resizable: true },
    {
      field: 'LoanName',
      headerName: 'Loan Name',
      resizable: true,
      minWidth: 500,
    },
    { field: 'PayFactor', headerName: 'PayFactor', resizable: true },
    { field: 'Minimum', headerName: 'Minimum', resizable: true },
    { field: 'Maximum', headerName: 'Maximum', resizable: true },
    { field: 'ContractorFee', headerName: 'Contractor Fee', resizable: true },
    { field: 'Interest', headerName: 'Interest', resizable: true },
    { field: 'BranchID', headerName: 'BranchID', resizable: true },
    {
      field: 'BankLoanName',
      headerName: 'Bank Loan Name',
      resizable: true,
      minWidth: 400,
    },
    {
      field: 'Modification User',
      headerName: 'ModificationUser',
      resizable: true,
    },
    {
      headerName: 'Edit/Delete',
      minWidth: 150,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
  ];
  const [columnDefs] = useState(columnsDefinition);

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
        <AgGridReact columnDefs={columnDefs} rowData={financingOffers} />
      </div>
    </>
  );
};
