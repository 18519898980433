import { useEffect, useState } from 'react';
import { IMarketingCopyDraft } from '../../interfaces/marketingCopyDraft';
import { ITaskCodeDraft } from '../../interfaces/taskCodeDraft';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const useDraftMarketingCopy = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [marketingDrafts, setMarketingDrafts] = useState<IMarketingCopyDraft[]>(
    [],
  );
  const { setLoader } = useLoader();
  const getDraftByMarketingCopyID = async (draftId: string, type: string) => {
    if (type === 'rowData') {
      return await axiosFetch({
        axiosInstance: arsInfoManagementApi,
        method: 'get',
        url: 'Draft/MarketingCopy',
        requestConfig: {
          params: { TaskCodeID: draftId, Type: type },
        },
      });
    } else {
      return await axiosFetch({
        axiosInstance: arsInfoManagementApi,
        method: 'get',
        url: 'Draft/MarketingCopy',
        requestConfig: {
          params: { DraftID: draftId, Type: type },
        },
      });
    }
  };

  useEffect(() => {
    if (response?.recordset) {
      setMarketingDrafts(response?.recordset);
    }
  }, [response]);

  const createMarketingDraft = async (taskCode: IMarketingCopyDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Draft/MarketingCopy',
      requestConfig: {
        data: taskCode,
      },
    });
  };

  const updateMarketingDraft = async (marketing: IMarketingCopyDraft) => {
    console.log(marketing);
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'put',
      url: 'Draft/MarketingCopy',
      requestConfig: {
        data: marketing,
        params: { TaskCodeID: marketing.TaskCodeID },
      },
    });
  };

  const deleteMarketingCopyDraft = async (id: string) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'delete',
      url: 'Draft/MarketingCopy',
      requestConfig: {
        params: { DraftID: Number(id) },
      },
    });
  };

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    marketingDrafts,
    loading,
    getDraftByMarketingCopyID,
    createMarketingDraft,
    updateMarketingDraft,
    deleteMarketingCopyDraft,
  };
};
