import { ICellRendererParams, RowNode } from "ag-grid-community";
import React from "react";
import Stack from "@mui/material/Stack";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MarketingCopyForm } from "../pages/taskCodePage/marketingCopyForm/MarketingCopyForm";

export const GridActionCell = (
  { data }: ICellRendererParams<RowNode>,
  onClickEdit: Function,
  onClickDelete: Function,
  onClickView: Function,
  type?: string
) => {
  // TODO replace any
  const onClickEditEvent = (event: any) => {
    event.preventDefault();
    onClickEdit(data);
  };
  const onClickViewEvent = (event: any) => {
    event.preventDefault();
    onClickView(data);
  };
  const onClickDelteEvent = (event: any) => {
    event.preventDefault();
    onClickDelete(data);
  };
  return (
    <>
      <Stack direction="row" spacing={2}>
        {type !== "promo" && (
          <>
            <IconButton
              sx={{ color: "#007DBB" }}
              onClick={onClickViewEvent}
              aria-label="delete"
            >
              <EditIcon />
            </IconButton>
          </>
        )}
        {type === "promo" && (
          <>
            <IconButton
              sx={{ color: "#007DBB" }}
              onClick={onClickEditEvent}
              aria-label="delete"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              sx={{ color: "#dc0464" }}
              onClick={onClickDelteEvent}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Stack>
    </>
  );
};
