import { DraftType } from '../../../../enums/DraftType';
import { IDraft } from '../../../../interfaces/draft';
import { TaskCodeForm } from '../../taskCodePage/taskCodeForm/TaskCodeForm';
import { ModalMode } from '../../../../config/enums';
import { DocumentForm } from '../../documentPage/documentForm/DocumentForm';
import { FinancingOfferForm } from '../../financingOfferPage/financingOfferForm/FinancingOfferForm';
import { PromotionForm } from '../../promotionPage/promotionForm/PromotionForm';
import { useDraftPromotion } from '../../../hooks/useDraftPromotion';
import { IPromotionDraft } from '../../../../interfaces/promotionDraft';
import { useEffect, useState } from 'react';
import { useDraftFinancing } from '../../../hooks/useDraftFinancing';
import { IFinancingOfferDraft } from '../../../../interfaces/financingDraft';
import { EditMarketing } from '../../taskCodePage/editMarketing/EditMarketing';
import { MarketingCopyForm } from '../../taskCodePage/marketingCopyForm/MarketingCopyForm';

interface IEditDraft {
  draft: IDraft;
  onCloseCreateEdit: Function;
}

export const EditDraft = ({ draft, onCloseCreateEdit }: IEditDraft) => {
  const { getDraftByPromotionID } = useDraftPromotion();
  const { getDraftByFinancingID } = useDraftFinancing();
  const [promotionDraft, setPromotionDraft] = useState<IPromotionDraft>();
  const [financingDraft, setFinancingDraft] = useState<IFinancingOfferDraft>();

  return (
    <>
      {draft.Type === DraftType.TaskCode && (
        <TaskCodeForm
          mode={ModalMode.Update}
          onCloseCreateEdit={onCloseCreateEdit}
          taskCodeId={draft.DraftID}
        />
      )}

      {draft.Type === DraftType.PDF && (
        <DocumentForm
          mode={ModalMode.Update}
          onCloseDocumentForm={onCloseCreateEdit}
          branchId={Number(draft.Branch)}
          rowData={{
            Path: draft.DraftID.toString(),
            Name: draft.DraftDescription,
          }}
          callFromDashbord={true}
        />
      )}
      {draft.Type === DraftType.FinancingOffer && (
        <FinancingOfferForm
          mode={ModalMode.Update}
          onCloseCreateEdit={onCloseCreateEdit}
          draftID={draft.DraftID}
        />
      )}
      {draft.Type === DraftType.Promotion && (
        <PromotionForm
          mode={ModalMode.Update}
          onCloseCreateEdit={onCloseCreateEdit}
          draftID={draft.DraftID}
        />
      )}
      {draft.Type === DraftType.MarketingCopy && (
        <MarketingCopyForm
          draftID={draft.DraftID}
          onCloseCreateEdit={onCloseCreateEdit}
        ></MarketingCopyForm>
      )}
    </>
  );
};
