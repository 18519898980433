import { useEffect, useState } from "react";
import { arsInfoManagementApi } from "../../service/arsInfoManagementApi";
import { useAxios } from "./useAxios";
import { useLoader } from "./useLoader";
import { ISchedulerDays } from "../../interfaces/schedulerDays";

export const useSchedulerDay = () => {
    const [response, error, loading, axiosFetch] = useAxios();
    const { setLoader } = useLoader();

    const getSchedulerDay = async () => {
        return await axiosFetch({
            axiosInstance: arsInfoManagementApi,
            method: 'get',
            url: 'Scheduler/ScheduledDay',
            requestConfig: {
                params: {},
            },
        });
    }

    useEffect(() => {
        setLoader(loading);
        return () => {
            setLoader(false);
        };
    }, [loading]);

    return {
        loading,
        getSchedulerDay
    };
};