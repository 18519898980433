import { Field, Form, Formik } from 'formik';
import { ModalMode } from '../../../../config/enums';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { uploadFile } from '../../../../service/azureBlobStorage';
import { IComponent } from '../../../../interfaces/taskCode';
import './styles.scss';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IMarketingCopyDraft } from '../../../../interfaces/marketingCopyDraft';
import { DbOperation } from '../../../../enums/DbOperation';
import { toast } from 'react-toastify';
import { useRole } from '../../../hooks/useRole';
import { useDraftMarketingCopy } from '../../../hooks/useDraftMarketingCopy';

interface ComponentFormInterface {
  value: IComponent;
  mode: ModalMode;
  onSubmit: Function;
  draftID?: string;
  components: IComponent[];
  taskCode?: string;
  branchID?: number;
  setOpenCreateEditModal: Function;
}

export const ComponentForm = ({
  value,
  mode,
  onSubmit,
  draftID,
  components,
  taskCode,
  branchID,
  setOpenCreateEditModal,
}: ComponentFormInterface) => {
  const {
    getDraftByMarketingCopyID,
    createMarketingDraft,
    updateMarketingDraft,
  } = useDraftMarketingCopy();
  const [file, setFile] = useState(null);
  const [taskCodeRow, setTaskCodeRow] = useState('');
  const [preview, setPreview] = useState('');
  const { userName, userADId } = useRole();
  const [componentRow, setComponentRow] = useState<any>();
  const [componentsData, setComponentsData] = useState<IComponent[]>([]);

  useEffect(() => {
    setComponentRow(value);
    setComponentsData(components);
    if (taskCode) {
      setTaskCodeRow(taskCode);
    }
    // create the preview
    if (!file) {
      setPreview('');
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  }, [file]);

  const updateComponents = (component: any) => {
    const indiceElemento = componentsData.findIndex(
      compo => compo.UniqueID == component.UniqueID,
    );
    componentsData.splice(indiceElemento, 1);
    componentsData.push(component);
    return componentsData;
  };
  const onSubmitForm = async (value: IComponent) => {
    const response = await uploadFile(file);
    const component = { ...value };
    if (response) {
      component.Picture = response;
    }

    const componentsEdit = updateComponents(component);
    if (!draftID) {
      const { recordset } = await getDraftByMarketingCopyID(
        taskCodeRow,
        'rowData',
      );
      const marketingDraft: IMarketingCopyDraft = {
        Components: JSON.stringify(componentsEdit),
        PublishID: 0,
        DBOperation: DbOperation.Update,
        ModificationUser: userName,
        ModificationUserId: userADId,
        TaskCodeID: taskCode,
        ComponentName: component.MarketingName,
        BranchID: branchID?.toString(),
      };

      if (recordset[0]) {
        await updateMarketingDraft(marketingDraft);
        toast.success('Marketing Copy update draft updated');
      } else {
        await createMarketingDraft(marketingDraft);
        toast.success('Marketing Copy draft created');
      }
    }
    setOpenCreateEditModal(false);
  };
  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <>
      <div>
        <h1>Edit Component</h1>
      </div>

      <Formik initialValues={value} onSubmit={onSubmitForm}>
        {({ values, errors, touched }) => (
          <Form className="form__compo">
            <div className="form__fields__fist">
              <div className="form__field">
                <label>Name</label>
                <span>
                  <Field
                    className="value__field__component"
                    id="Name"
                    name="Name"
                    placeholder="Name"
                    disabled
                  />
                  <small>
                    {touched.Name && errors.Name && <div>{errors.Name}</div>}
                  </small>
                </span>
              </div>
              <div className="form__field">
                <label>Make</label>
                <span>
                  <Field
                    className="value__field__component"
                    id="Make"
                    name="Make"
                    placeholder="Make"
                    disabled
                  />
                  <small>
                    {touched.Make && errors.Make && <div>{errors.Make}</div>}
                  </small>
                </span>
              </div>
              <div className="form__field">
                <label>Model</label>
                <span>
                  <Field
                    className="value__field__component"
                    id="Model"
                    name="Model"
                    placeholder="Model"
                    disabled
                  />
                  <small>
                    {touched.Model && errors.Model && <div>{errors.Model}</div>}
                  </small>
                </span>
              </div>
            </div>
            <div className="form__field">
              <label>Marketing Name </label>
              <span>
                <Field
                  className="value__field__component__large"
                  id="MarketingName"
                  name="MarketingName"
                  placeholder="Marketing Name"
                />
                <small>
                  {touched.MarketingName && errors.MarketingName && (
                    <div>{errors.MarketingName}</div>
                  )}
                </small>
              </span>
            </div>
            <div className="form__field">
              <label>Picture</label>
              <div className="picture__section">
                <span>
                  <img
                    className="card-img"
                    src={preview ? preview : values.Picture}
                    alt=""
                  />
                </span>
              </div>
              <span className="drop__image__document">
                <input
                  className="input__file"
                  type="file"
                  id="images"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <label htmlFor="images">
                  <AddCircleOutlineIcon className="input__file__icon" />
                </label>
                <label className="title__add__image"> Add Image</label>
              </span>
            </div>
            <div className="button__save__component">
              <Button
                type="submit"
                variant="contained"
                className="button__save__task"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
