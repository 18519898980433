import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridActionCell } from '../../../components/GridActionCell';
import './styles.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GridReadyEvent, IDatasource } from 'ag-grid-community';
import { useGtc } from '../../../hooks/useGtc';

interface ITaskCodeGrid {
  selectedBranch: number;
  onClickEdit: Function;
  onClickDelete: Function;
  onClickView: Function;
}
interface IFilter {
  branchID: number;
  textFilter?: string;
}

export const TaskCodeGrid = ({
  selectedBranch,
  onClickEdit,
  onClickDelete,
  onClickView,
}: ITaskCodeGrid) => {
  const handleCellRenderer = (data: any) => {
    return GridActionCell(data, onClickEdit, onClickDelete, onClickView);
  };
  const { getTaskCodes } = useGtc();
  const [filter, setFilter] = useState<IFilter>({
    branchID: selectedBranch,
    textFilter: '',
  });
  const [timeoutId, setTimeoutId] = useState<
    string | number | NodeJS.Timeout | undefined
  >(undefined);

  useEffect(() => {
    setFilter(filter => ({ ...filter, branchID: selectedBranch }));
  }, [selectedBranch]);

  useEffect(() => {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        handleFilterClick(filter);
      }, 1000),
    );
  }, [filter]);
  const gridRef: any = useRef();

  const onGridReady = useCallback((params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      getRows: async params => {
        const { recordset, total } = await getTaskCodes(
          params.filterModel.OrganizationalUnitID?.filter,
          params.filterModel.globalFilter?.filter,
          params.startRow,
          params.endRow,
        );

        params.successCallback(recordset, total);
      },
    };
    params.api.setDatasource(dataSource);
  }, []);

  const handleFilterClick = ({ branchID, textFilter }: IFilter) => {
    if (!gridRef.current.api) {
      return;
    }
    const filterModel = gridRef.current.api.getFilterModel();
    if (branchID) {
      filterModel.OrganizationalUnitID = {
        filterType: 'text',
        type: 'equals',
        filter: branchID,
      };
    }
    filterModel.globalFilter = {
      filterType: 'text',
      type: 'contains',
      filter: textFilter,
    };
    gridRef.current.api.setFilterModel(filterModel);
  };
  const onFilterTextBoxChanged = (event: any) => {
    setFilter({
      branchID: selectedBranch,
      textFilter: event.target.value,
    });
  };

  const columnsDefinition: any[] = [
    { field: 'ID', headerName: 'ID', resizable: true, filter: true },
    {
      field: 'ResourceID',
      headerName: 'Resource ID',
      filter: true,
      resizable: true,
    },
    {
      field: 'UniqueID',
      headerName: 'Unique ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'Name',
      headerName: 'Name',
      resizable: true,
      filter: true,
    },
    {
      field: 'Category',
      headerName: 'Category',
      resizable: true,
      filter: true,
    },
    {
      field: 'StandardCost',
      headerName: 'Standard Cost',
      resizable: true,
      filter: true,
    },
    {
      field: 'CompanyStructureID',
      headerName: 'Company Structure ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'OptionID',
      headerName: 'Option ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'OptionName',
      headerName: 'Option Name',
      resizable: true,
      filter: true,
    },
    { field: 'AHRI', headerName: 'AHRI', resizable: true, filter: true },
    {
      field: 'CoolingBTU',
      headerName: 'Cooling BTU',
      resizable: true,
      filter: true,
    },
    {
      field: 'HeatingBTU',
      headerName: 'Heating BTU',
      resizable: true,
      filter: true,
    },
    { field: 'EER', headerName: 'EER', resizable: true, filter: true },
    {
      field: 'OptionUniqueID',
      headerName: 'Option Unique ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'customField',
      headerName: 'Custom Field',
      resizable: true,
      filter: true,
    },
    {
      field: 'customPricing',
      headerName: 'Custom Pricing',
      resizable: true,
      filter: true,
    },
    {
      field: 'OrganizationalUnitID',
      headerName: 'Organizational Unit ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'RequiresCustomPrice',
      headerName: 'Requires Custom Price',
      resizable: true,
      filter: true,
    },
    {
      field: 'BusinessUnitName',
      headerName: 'Business Unit Name',
      resizable: true,
      filter: true,
    },
    {
      field: 'Category',
      headerName: 'Category',
      resizable: true,
      filter: true,
    },
    {
      field: 'ResourceUniqueID',
      headerName: 'Resource Unique ID',
      resizable: true,
      filter: true,
    },
    {
      field: 'TaxCategory',
      headerName: 'Tax Category',
      resizable: true,
      filter: true,
    },
    {
      field: 'SEER',
      headerName: 'SEER',
      resizable: true,
      filter: true,
    },
    {
      field: 'AFUE',
      headerName: 'AFUE',
      resizable: true,
      filter: true,
    },
    {
      field: 'Series',
      headerName: 'Series',
      resizable: true,
      filter: true,
    },
    {
      field: 'ConfigurationType',
      headerName: 'Configuration Type',
      resizable: true,
      filter: true,
    },
    {
      field: 'CoolingTonnage',
      headerName: 'Cooling Tonnage',
      resizable: true,
      filter: true,
    },
    {
      field: 'Make',
      headerName: 'Make',
      resizable: true,
      filter: true,
    },
    {
      field: 'ModificationUser',
      headerName: 'Modification User',
      resizable: true,
      filter: true,
    },
    {
      headerName: 'Edit Component',
      minWidth: 50,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
    {
      colId: 'globalFilter',
      maxWidth: 0,
      minWidth: 0,
      width: 0,
      filter: true,
      hide: true,
    },
  ];
  const [columnDefs] = useState(columnsDefinition);

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
    };
  }, []);

  return (
    <>
      <div>
        <Box className="box__filter">
          <TextField
            id="filter-text-box"
            variant="outlined"
            value={filter.textFilter}
            placeholder="Search"
            onChange={onFilterTextBoxChanged}
            className="input__filter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>

      <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          ref={gridRef}
          rowModelType="infinite"
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
};
