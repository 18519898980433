import { BlobServiceClient } from '@azure/storage-blob';
import { AzureFolderContainer } from '../enums/AzureFolderContainer';

// TODO move to custom hook
const accountName = process.env.REACT_APP_AZURE_ACCOUNT;
const containerName = AzureFolderContainer.ContainerComponentsImages;
const sas = process.env.REACT_APP_AZURE_SAS;

const storageBlobEndpoint = `https://${accountName}.blob.core.windows.net`;
const blobServiceClient = new BlobServiceClient(`${storageBlobEndpoint}${sas}`);

export const uploadFile = async (file: any, component: string = 'newblob') => {
  if (!file) {
    return '';
  }
  const extension = file.name.split('.').pop();
  const containerClient = blobServiceClient.getContainerClient(containerName);
  if (!(await containerClient.exists())) {
    return '';
  }
  const blobName = `${component}_${new Date().getTime()}.${extension}`;
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  const uploadBlobResponse = await blockBlobClient.upload(file, file.size);
  if (uploadBlobResponse.errorCode) {
    return '';
  }
  return await getBlockBlobUrl(blobName);
};

export const getBlockBlobUrl = async (blobName: string): Promise<string> => {
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  if (!(await blockBlobClient.exists())) {
    return '';
  }
  const url = blockBlobClient.url.split('?')[0];
  return url;
};
