import { AppRouter } from "./router/AppRouter";
import { AppTheme } from "./theme/AppTheme";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Loader } from "./ars/loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigCatProvider } from "configcat-react";

export const App = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIG_CAT || ""}>
      <MsalProvider instance={msalInstance}>
        <Loader>
          <AppTheme>
            <AppRouter />
          </AppTheme>
        </Loader>
        <ToastContainer autoClose={3000} hideProgressBar={true} />
      </MsalProvider>
    </ConfigCatProvider>
  );
};
