import { AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import { GridActionCell } from '../../../components/GridActionCell';
import { IPromotion } from '../../../../interfaces/promotion';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface PromotionGridInferface {
  promotions: IPromotion[];
  onClickEdit: Function;
  onClickDelete: Function;
}

export const PromotionGrid = ({
  promotions,
  onClickEdit,
  onClickDelete,
}: PromotionGridInferface) => {
  const handleCellRenderer = (data: any) => {
    return GridActionCell(
      data,
      onClickEdit,
      onClickDelete,
      onClickEdit,
      'promo',
    );
  };
  const columnsDefinition: any[] = [
    { field: 'id', headerName: 'Id', resizable: true },
    { field: 'name', headerName: 'Name', resizable: true, minWidth: 500 },
    { field: 'additional', headerName: 'Additional', resizable: true },
    { field: 'branchNumber', headerName: 'Branch Number', resizable: true },
    { field: 'percent', headerName: 'Percent', resizable: true },
    { field: 'number', headerName: 'Number', resizable: true },
    {
      field: 'ModificationUser',
      headerName: 'Modification User',
      resizable: true,
    },
    {
      headerName: 'Edit/Delete',
      minWidth: 150,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
  ];
  const [columnDefs] = useState(columnsDefinition);

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
        <AgGridReact columnDefs={columnDefs} rowData={promotions} />
      </div>
    </>
  );
};
