import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import usersData from "./users.json";
import "./styles.scss";
import logo from "./ars-logo.svg";
import { Spinner } from "../../components/spinner/Spinner";
import { CircularProgress } from "@mui/material";

interface LoginPageProps {
  onLogin: (isLoggedIn: boolean) => void;
}

export const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateUser = (username: string, password: string) => {
    const userFound = usersData.users.find(
      (user) => user.username === username && user.password === password
    );
    if (userFound) {
      localStorage.setItem("username", userFound.name);
      return true;
    }
    return false;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      if (validateUser(username, password)) {
        setLoginError("");
        onLogin(true);

        navigate("/scheduler");
      } else {
        setLoginError("Invalid username or password");
        onLogin(false);
      }
      setLoading(false);
    }, 2000);
  };

  const isFormFilled = username.trim() !== "" && password.trim() !== "";

  return (
    <div className="login-container">
      <div className="login">
        <img className="logo" src={logo} alt="FromDigital" />
        <div className="title">
          <div className="maintenance">MAINTENANCE</div>
          <div className="screens">SCREENS</div>
        </div>
      </div>
      <div className="login-fields">
        <input
          className={loginError ? "input-error" : ""}
          type="text"
          placeholder="User"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
        />
        <input
          className={loginError ? "input-error" : ""}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <button onClick={handleSubmit} disabled={!isFormFilled || loading}>
          {loading ? (
            <CircularProgress
              size={24}
              style={{ marginLeft: "0.5rem", color: "black" }}
            />
          ) : (
            "Login"
          )}
        </button>
        {loginError && <div className="error-message">{loginError}</div>}
      </div>
    </div>
  );
};
