import { useEffect, useState } from 'react';
import { ITaskCodeDraft } from '../../interfaces/taskCodeDraft';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const useDraftGtc = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [taskCodeDrafts, setTaskCodeDrafts] = useState<ITaskCodeDraft[]>([]);
  const { setLoader } = useLoader();
  const getDraftByTaskCodeID = async (id: string) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Draft/TaskCode',
      requestConfig: {
        params: { id },
      },
    });
  };
  useEffect(() => {
    if (response?.recordset) {
      setTaskCodeDrafts(response?.recordset);
    }
  }, [response]);

  const createTaskCodeDraft = async (taskCode: ITaskCodeDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Draft/TaskCode',
      requestConfig: {
        data: taskCode,
      },
    });
  };

  const updateTaskCodeDraft = async (taskCode: ITaskCodeDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'put',
      url: 'Draft/TaskCode',
      requestConfig: {
        data: taskCode,
        params: { id: taskCode.ID },
      },
    });
  };

  const deleteTaskCodeDraft = async (id: string) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'delete',
      url: 'Draft/TaskCode',
      requestConfig: {
        params: { id },
      },
    });
  };

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    taskCodeDrafts,
    loading,
    getDraftByTaskCodeID,
    createTaskCodeDraft,
    updateTaskCodeDraft,
    deleteTaskCodeDraft,
  };
};
