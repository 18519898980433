import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { IDropdownOption } from '../components/dropdown/Dropdown';
import { useFeatureFlag } from 'configcat-react';

export const useRole = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [role, setRole] = useState<number>();
  const [userName, setUserName] = useState('');
  const [userADId, setUserADId] = useState('');
  const [roleBranchOptions, setRoleBranchOptions] = useState<IDropdownOption[]>(
    [],
  );
  const { value: branchOptionsValue, loading: branchOptionsLoading } =
    useFeatureFlag('branchOptions', 'Default');

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      setUserName(accounts[0].name || accounts[0].username);
      setUserADId(accounts[0].localAccountId);
    }
  }, [inProgress, isAuthenticated, accounts, instance]);

  useEffect(() => {
    setRoleBranchOptions([]);
    if (branchOptionsValue !== 'Default') {
      const { BranchOptions } = JSON.parse(branchOptionsValue);

      setRoleBranchOptions(BranchOptions);
    }
  }, [branchOptionsLoading]);
  return { role, userName, userADId, roleBranchOptions };
};
