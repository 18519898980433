import { AgGridReact } from 'ag-grid-react'
import { useState } from 'react'
import { IDocument } from '../../../../interfaces/document'
import { GridActionCell } from '../../../components/GridActionCell'

interface IDocumentGrid {
  documents: IDocument[]
  onClickEdit: Function
  onClickDelete: Function
}
export const DocumentGrid = ({
  documents,
  onClickEdit,
  onClickDelete,
}: IDocumentGrid) => {
  const handleCellRenderer = (data: any) => {
    return GridActionCell(
      data,
      onClickEdit,
      onClickDelete,
      onClickEdit,
      'promo',
    )
  }
  const columnsDefinition: any[] = [
    {
      field: 'FileName',
      headerName: 'Name',
      resizable: true,
      initialWidth: 600,
      sortable: true,
    },
    {
      field: 'Name',
      headerName: 'File Name',
      resizable: true,
      initialWidth: 500,
      sortable: true,
    },
    {
      field: 'ModificationUser',
      headerName: 'Modification User',
      resizable: true,
      initialWidth: 400,
    },
    {
      headerName: 'Edit/Delete',
      minWidth: 150,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
  ]
  const [columnDefs, setColumnDefs] = useState(columnsDefinition)

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
        <AgGridReact columnDefs={columnDefs} rowData={documents} />
      </div>
    </>
  )
}
