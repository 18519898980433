import { useEffect, useState } from 'react';
import { IPromotionDraft } from '../../interfaces/promotionDraft';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const useDraftPromotion = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [promotionDrafts, setPromotionDrafts] = useState<IPromotionDraft[]>([]);
  const { setLoader } = useLoader();
  const getDraftByPromotionID = async (id: string) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Draft/Promotion',
      requestConfig: {
        params: { id },
      },
    });
  };
  useEffect(() => {
    if (response?.recordset) {
      setPromotionDrafts(response?.recordset);
    }
  }, [response]);

  const getPromotionDraftByDraftID = async (draftId: string) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Draft/Promotion',
      requestConfig: {
        params: { draftid: draftId },
      },
    });
  };
  useEffect(() => {
    if (response?.recordset) {
      setPromotionDrafts(response?.recordset);
    }
  }, [response]);

  const createPromotionDraft = async (promotion: IPromotionDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'post',
      url: 'Draft/Promotion',
      requestConfig: {
        data: promotion,
      },
    });
  };

  const updatePromotionDraft = async (promotion: IPromotionDraft) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'put',
      url: 'Draft/Promotion',
      requestConfig: {
        data: promotion,
        params: { draftid: promotion.DraftID },
      },
    });
  };

  const deletePromotionDraft = async (draftid: string) => {
    await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'delete',
      url: 'Draft/Promotion',
      requestConfig: {
        params: { draftid },
      },
    });
  };

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return {
    promotionDrafts,
    loading,
    getDraftByPromotionID,
    getPromotionDraftByDraftID,
    createPromotionDraft,
    updatePromotionDraft,
    deletePromotionDraft,
  };
};
