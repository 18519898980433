import { Button, MenuItem, Select, Switch, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { DbOperation } from '../../../../enums/DbOperation';
import { IDocument } from '../../../../interfaces/document';
import './styles.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IDocumentConfiguration } from '../../../../interfaces/documentJson';
import { ModalMode } from '../../../../config/enums';
import { useDocument } from '../../../hooks/useDocument';
import { toFile } from '../../../../helpers/blobHelper';
import { toast } from 'react-toastify';
import { MultiselecInput } from '../../../components/multiselect/MultiselectInput';
import { v4 as uuidv4 } from 'uuid';
import { jsonMap } from '../../../../map/jsonMap';
import { Draft07 } from 'json-schema-library';
import * as jsonSchema from './../../../../map/jsonSchema.json';
import { DocumentJsonEditor } from '../documentJsonEditor/DocumentJsonEditor';

var objectMapper = require('object-mapper');

interface IDocumentForm {
  rowData?: IDocument;
  branchId: number;
  onCloseDocumentForm: Function;
  mode: ModalMode;
  callFromDashbord: Boolean;
}
const documentJson: IDocumentConfiguration = {
  accentColorofSelectedDesigns: '',
  accentColorofUnselectedDesigns: '',
  branchIdsSupported: [],
  documentOrder: null,
  EHLFlag: '',
  id: '',
  line: '',
  maxCountOfPreExistingDamage: '',
  maxCountOfSelectedDesignRooms: '',
  maxCountOfSelectedDesigns: '',
  maxCountOfUnselectedDesignRooms: '',
  maxCountOfUnselectedDesigns: '',
  maxCountWorkPerformed: '',
  maxCountWorkRecommended: '',
  maxHeightOfInstallationWorkItems: '',
  maxHeightofTaskCode: [], //'[477.909,552.605]'
  maxHeightofUnselectedDesigns: [], //'[641.2896,893.3376,737.472]'
  maxWidthofTaskCode: '',
  maxWidthOfInstallationWorkItems: '',
  maxWidthofUnselectedTaskCode: '',
  name: '',
  pages: null,
  pagesWithSignatures: [],
  required: 'false', //es 'true' o 'false'
  type: '',
};

const documentJsonSchema = Yup.object().shape({
  accentColorofSelectedDesigns: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  accentColorofUnselectedDesigns: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  documentOrder: Yup.number().nullable(),
  EHLFlag: Yup.string(),
  id: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  line: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  maxCountOfPreExistingDamage: Yup.number().nullable(),
  maxCountOfSelectedDesignRooms: Yup.number().nullable(),
  maxCountOfSelectedDesigns: Yup.number().nullable(),
  maxCountOfUnselectedDesignRooms: Yup.number().nullable(),
  maxCountOfUnselectedDesigns: Yup.number().nullable(),
  maxCountWorkPerformed: Yup.number().nullable(),
  maxCountWorkRecommended: Yup.number().nullable(),
  maxHeightOfInstallationWorkItems: Yup.number().nullable(),
  maxHeightofTaskCode: Yup.array(),
  maxHeightofUnselectedDesigns: Yup.array(),
  maxWidthofTaskCode: Yup.number().nullable(),
  maxWidthOfInstallationWorkItems: Yup.number().nullable(),
  maxWidthofUnselectedTaskCode: Yup.number().nullable(),
  name: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .required()
    .matches(
      /\w*[a-zA-Z]\w*/g,
      'The name must contain alphabetic digits and must not contain special characters',
    ),
  pages: Yup.number().nullable(),
  pagesWithSignatures: Yup.array(), //number[]
  required: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(), //es 'true' o 'false'
  type: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
});

const schema = new Draft07(jsonSchema);

export const DocumentForm = ({
  rowData,
  branchId,
  onCloseDocumentForm,
  mode,
  callFromDashbord,
}: IDocumentForm) => {
  const {
    downloadBlobPdf,
    downloadBlobJson,
    downloadDraftJson,
    downloadDraftPdf,
  } = useDocument();
  const [filePdf, setFilePdf] = useState<File | null>(null);
  const [fileJson, setFileJson] = useState<IDocumentConfiguration>();
  const { uploadDraftBlobJson, uploadDraftBlobPdf, getMetadata } =
    useDocument();
  const [filePdfUpload, setFilePdfUpload] = useState('');
  const [showDocumentJsonEditor, setShowDocumentJsonEditor] = useState(false);
  const [fileName, setFileName] = useState(
    rowData?.Path.split('contracts/')[1] || '',
  );

  let title;
  if (mode === ModalMode.Create) title = 'New Document';
  if (mode === ModalMode.Update) title = 'Edit Document';

  //intitiliazer
  useEffect(() => {
    if (mode === ModalMode.Update && rowData) {
      let blobPath = '';
      blobPath = rowData.Path;
      downloadDocumentFiles(blobPath);
    }
    if (mode === ModalMode.Create) {
      setFileJson({ ...documentJson, branchIdsSupported: [Number(branchId)] });
    }
  }, []);

  const downloadDocumentFiles = async (blobPath: string) => {
    let downloadJsonFile: any;
    let downloadPdfFile: any;
    if (blobPath.includes('branch-')) {
      downloadJsonFile = await downloadDraftJson(blobPath);
      downloadPdfFile = await downloadDraftPdf(blobPath);
    } else {
      downloadJsonFile = await downloadBlobJson(branchId, blobPath);
      downloadPdfFile = await downloadBlobPdf(branchId, blobPath);
    }
    if (!downloadJsonFile) {
      toast.error('Fail to download the document');
      onCloseDocumentForm();
    }

    const parsedDownloadJsonFile = JSON.parse(downloadJsonFile);
    const mappedJson = objectMapper(parsedDownloadJsonFile, jsonMap);
    setFileJson(mappedJson);
    if (downloadPdfFile) {
      const pdf: any = await toFile(
        downloadPdfFile,
        blobPath?.split('/')[1] + '.pdf',
      );
      setFilePdf(pdf);
      setFilePdfUpload(downloadPdfFile);
    }
  };

  const handleFileChangePdf = async (event: any) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        setFilePdfUpload(String(reader.result));
      };
      setFilePdf(event.target.files[0]);
    }
  };

  const handleFileNameChange = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9_]*$') != null) {
      setFileName(e.target.value);
    }
  };
  const onSubmit = async (value: IDocumentConfiguration) => {
    const error = schema.validate(value);
    if (error.length > 0) {
      error.forEach(value => {
        toast.error(value.message);
      });
      console.log(`Incorrect json format : ${JSON.stringify(error)}`);
      return;
    }
    if (!filePdf) {
      toast.warn('Upload a PDF file');
      return;
    }
    if (!fileName) {
      toast.warn('Add a file name');
      return;
    }
    let success = true;
    if (mode === ModalMode.Create) {
      // const documentName = uuidv4();

      success =
        success &&
        (await uploadDraftBlobPdf(
          branchId,
          filePdf,
          fileName,
          DbOperation.Insert,
        ));
      success =
        success &&
        (await uploadDraftBlobJson(
          branchId,
          value,
          fileName,
          DbOperation.Insert,
        ));
    }
    if (mode === ModalMode.Update && rowData) {
      let operation = DbOperation.Update;
      if (callFromDashbord) {
        const metadata = await getMetadata(`${rowData.Path}.pdf`);
        let dbop = metadata?.dboperation as DbOperation;
        if (!dbop) {
          success = false;
          return false;
        }
        operation = dbop;
      }
      success =
        success &&
        (await uploadDraftBlobPdf(branchId, filePdf, rowData.Name, operation));
      success =
        success &&
        (await uploadDraftBlobJson(branchId, value, rowData.Name, operation));
    }

    success
      ? toast.success('Document draft created')
      : toast.error('Document draft not created');

    onCloseDocumentForm();
  };
  return (
    <>
      <div>
        <h1>{title}</h1>
      </div>
      {fileJson && (
        <div className="box__document">
          <div className="box__title">
            <div className="box__name">
              <label>File name</label>
              {mode === ModalMode.Create && (
                <input
                  className="value__field__title"
                  id="name"
                  name="name"
                  value={fileName}
                  onChange={handleFileNameChange}
                />
              )}
              {mode === ModalMode.Update && (
                <span className="value__field__name">{fileName}</span>
              )}
            </div>
            <Button
              onClick={() => {
                setShowDocumentJsonEditor(true);
              }}
              variant="outlined"
            >
              Edit Json
            </Button>
          </div>

          <Formik
            initialValues={fileJson}
            onSubmit={onSubmit}
            validationSchema={documentJsonSchema}
          >
            {({ values, errors, touched, setValues, setFieldValue }) => (
              <Form>
                <div className="box__configuration">
                  <div className="document__field">
                    <label>Name</label>
                    <span>
                      <Field
                        className="value__field"
                        id="name"
                        name="name"
                        value={values.name ?? ''}
                      />
                      <small>
                        {touched.name && errors.name && (
                          <div className="message__error">{errors.name}</div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* accentColorofSelectedDesigns */}
                  <div className="document__field">
                    <label>Accent Color of Selected Designs</label>
                    <span>
                      <Field
                        className="value__field"
                        id="accentColorofSelectedDesigns"
                        name="accentColorofSelectedDesigns"
                        value={values.accentColorofSelectedDesigns ?? ''}
                      />
                      <small>
                        {touched.accentColorofSelectedDesigns &&
                          errors.accentColorofSelectedDesigns && (
                            <div className="message__error">
                              {errors.accentColorofSelectedDesigns}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* accentColorofUnselectedDesigns */}
                  <div className="document__field">
                    <label>Accent Color of Unselected Designs</label>
                    <span>
                      <Field
                        className="value__field"
                        id="accentColorofUnselectedDesigns"
                        name="accentColorofUnselectedDesigns"
                        value={values.accentColorofUnselectedDesigns ?? ''}
                      />
                      <small>
                        {touched.accentColorofUnselectedDesigns &&
                          errors.accentColorofUnselectedDesigns && (
                            <div className="message__error">
                              {errors.accentColorofUnselectedDesigns}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* documentOrder */}
                  <div className="document__field">
                    <Tooltip title={'Document order for Setup Documents'} arrow>
                      <label>Document Order</label>
                    </Tooltip>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="adocumentOrder"
                        name="documentOrder"
                        value={values.documentOrder ?? ''}
                        type={'number'}
                      />
                      <small>
                        {touched.documentOrder && errors.documentOrder && (
                          <div className="message__error">
                            {errors.documentOrder}
                          </div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* EHLFlag */}
                  <div className="document__field">
                    <label>EHLFlag</label>
                    <span>
                      <Field
                        className="value__field"
                        id="EHLFlag"
                        name="EHLFlag"
                        value={values.EHLFlag ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'EHLFlag',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.EHLFlag && errors.EHLFlag && (
                          <div className="message__error">{errors.EHLFlag}</div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* id */}
                  <div className="document__field">
                    <label>id</label>
                    <span>
                      <Field
                        className="value__field"
                        id="id"
                        name="id"
                        value={values.id ?? ''}
                      />
                      <small>
                        {touched.id && errors.id && (
                          <div className="message__error">{errors.id}</div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* line */}
                  <div className="document__field">
                    <Tooltip title={'Service Line e.g. AOR'} arrow>
                      <label>Line</label>
                    </Tooltip>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="line"
                        name="line"
                        value={values.line ?? ''}
                      />
                      <small>
                        {touched.line && errors.line && (
                          <div className="message__error">{errors.line}</div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountOfPreExistingDamage */}
                  <div className="document__field">
                    <label>Max Count Of PreExisting Damage</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountOfPreExistingDamage"
                        name="maxCountOfPreExistingDamage"
                        value={values.maxCountOfPreExistingDamage ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountOfPreExistingDamage',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountOfPreExistingDamage &&
                          errors.maxCountOfPreExistingDamage && (
                            <div className="message__error">
                              {errors.maxCountOfPreExistingDamage}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountOfSelectedDesignRooms */}
                  <div className="document__field">
                    <label>Max Count Of Selected Design Rooms</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountOfSelectedDesignRooms"
                        name="maxCountOfSelectedDesignRooms"
                        value={values.maxCountOfSelectedDesignRooms ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountOfSelectedDesignRooms',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountOfSelectedDesignRooms &&
                          errors.maxCountOfSelectedDesignRooms && (
                            <div className="message__error">
                              {errors.maxCountOfSelectedDesignRooms}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountOfSelectedDesigns */}
                  <div className="document__field">
                    <label>Max Count Of Selected Designs</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountOfSelectedDesigns"
                        name="maxCountOfSelectedDesigns"
                        value={values.maxCountOfSelectedDesigns ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountOfSelectedDesigns',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountOfSelectedDesigns &&
                          errors.maxCountOfSelectedDesigns && (
                            <div className="message__error">
                              {errors.maxCountOfSelectedDesigns}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountOfUnselectedDesignRooms */}
                  <div className="document__field">
                    <label>Max Count Of Unselected Design Rooms</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountOfUnselectedDesignRooms"
                        name="maxCountOfUnselectedDesignRooms"
                        value={values.maxCountOfUnselectedDesignRooms ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountOfUnselectedDesignRooms',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountOfUnselectedDesignRooms &&
                          errors.maxCountOfUnselectedDesignRooms && (
                            <div className="message__error">
                              {errors.maxCountOfUnselectedDesignRooms}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountOfUnselectedDesigns */}
                  <div className="document__field">
                    <label>Max Count Of Unselected Designs</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountOfUnselectedDesigns"
                        name="maxCountOfUnselectedDesigns"
                        value={values.maxCountOfUnselectedDesigns ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountOfUnselectedDesigns',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountOfUnselectedDesigns &&
                          errors.maxCountOfUnselectedDesigns && (
                            <div className="message__error">
                              {errors.maxCountOfUnselectedDesigns}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountWorkPerformed */}
                  <div className="document__field">
                    <label>Max Count Work Performed</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountWorkPerformed"
                        name="maxCountWorkPerformed"
                        value={values.maxCountWorkPerformed ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountWorkPerformed',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountWorkPerformed &&
                          errors.maxCountWorkPerformed && (
                            <div className="message__error">
                              {errors.maxCountWorkPerformed}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxCountWorkRecommended */}
                  <div className="document__field">
                    <label>Max Count Work Recommended</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxCountWorkRecommended"
                        name="maxCountWorkRecommended"
                        value={values.maxCountWorkRecommended ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxCountWorkRecommended',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxCountWorkRecommended &&
                          errors.maxCountWorkRecommended && (
                            <div className="message__error">
                              {errors.maxCountWorkRecommended}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxHeightOfInstallationWorkItems */}
                  <div className="document__field">
                    <label>Max Height of Installation Work Items</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxHeightOfInstallationWorkItems"
                        name="maxHeightOfInstallationWorkItems"
                        value={values.maxHeightOfInstallationWorkItems ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxHeightOfInstallationWorkItems',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxHeightOfInstallationWorkItems &&
                          errors.maxHeightOfInstallationWorkItems && (
                            <div className="message__error">
                              {errors.maxHeightOfInstallationWorkItems}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxHeightofTaskCode */}
                  <div className="document__field">
                    <label>Max Height of TaskCode</label>
                    <span id="val">
                      <MultiselecInput
                        type={'number'}
                        onChange={(array: []) => {
                          setFieldValue('maxHeightofTaskCode', array);
                        }}
                        value={values.maxHeightofTaskCode}
                      />

                      <small>
                        {touched.maxHeightofTaskCode &&
                          errors.maxHeightofTaskCode && (
                            <div>{errors.maxHeightofTaskCode}</div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxHeightofUnselectedDesigns */}
                  <div className="document__field">
                    <label>Max Height of Unselected Designs</label>
                    <span id="val">
                      <MultiselecInput
                        onChange={(array: []) => {
                          setFieldValue('maxHeightofUnselectedDesigns', array);
                        }}
                        type={'number'}
                        value={values.maxHeightofUnselectedDesigns}
                      />

                      <small>
                        {touched.maxHeightofUnselectedDesigns &&
                          errors.maxHeightofUnselectedDesigns && (
                            <div className="message__error">
                              {errors.maxHeightofUnselectedDesigns}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxWidthofTaskCode */}
                  <div className="document__field">
                    <label>Max Width of TaskCode</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxWidthofTaskCode"
                        name="maxWidthofTaskCode"
                        value={values.maxWidthofTaskCode ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxWidthofTaskCode',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxWidthofTaskCode &&
                          errors.maxWidthofTaskCode && (
                            <div className="message__error">
                              {errors.maxWidthofTaskCode}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxWidthOfInstallationWorkItems */}
                  <div className="document__field">
                    <label>Max Width of Installation Work Items</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxWidthOfInstallationWorkItems"
                        name="maxWidthOfInstallationWorkItems"
                        value={values.maxWidthOfInstallationWorkItems ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxWidthOfInstallationWorkItems',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxWidthOfInstallationWorkItems &&
                          errors.maxWidthOfInstallationWorkItems && (
                            <div className="message__error">
                              {errors.maxWidthOfInstallationWorkItems}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* maxWidthofUnselectedTaskCode */}
                  <div className="document__field">
                    <label>Max Width of Unselected TaskCode</label>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="maxWidthofUnselectedTaskCode"
                        name="maxWidthofUnselectedTaskCode"
                        value={values.maxWidthofUnselectedTaskCode ?? ''}
                        type={'number'}
                        onChange={(event: any) =>
                          setFieldValue(
                            'maxWidthofUnselectedTaskCode',
                            event.target.value.toString(),
                          )
                        }
                      />
                      <small>
                        {touched.maxWidthofUnselectedTaskCode &&
                          errors.maxWidthofUnselectedTaskCode && (
                            <div className="message__error">
                              {errors.maxWidthofUnselectedTaskCode}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* pages */}
                  <div className="document__field">
                    <Tooltip title={'Number of pages of the documnet'} arrow>
                      <label>Pages</label>
                    </Tooltip>
                    <span id="val">
                      <Field
                        className="value__field"
                        id="pages"
                        name="pages"
                        value={values.pages ?? ''}
                        type={'number'}
                      />
                      <small>
                        {touched.pages && errors.pages && (
                          <div>{errors.pages}</div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* pagesWithSignatures */}
                  <div className="document__field">
                    <Tooltip
                      title="Add the pages that have a signature field e.g. 0 for the first page, 1 for the second page, etc."
                      arrow
                    >
                      <label>Pages With Signatures</label>
                    </Tooltip>
                    <span id="val">
                      <MultiselecInput
                        onChange={(array: []) => {
                          setFieldValue('pagesWithSignatures', array);
                        }}
                        type={'number'}
                        value={values.pagesWithSignatures}
                      />

                      <small>
                        {touched.pagesWithSignatures &&
                          errors.pagesWithSignatures && (
                            <div className="message__error">
                              {errors.pagesWithSignatures}
                            </div>
                          )}
                      </small>
                    </span>
                  </div>
                  {/* required */}
                  <div className="document__field">
                    <Tooltip
                      title="On, will Place the Document at Required Contracts. Off, will Place the Document at Additional Contract Components"
                      arrow
                    >
                      <label>Required</label>
                    </Tooltip>
                    <span id="val" className="switch__requiered">
                      <Switch
                        checked={values.required === 'true' ? true : false}
                        onChange={event =>
                          setValues((prev: IDocumentConfiguration) => ({
                            ...prev,
                            required: event.target.checked.toString(),
                          }))
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <small>
                        {touched.required && errors.required && (
                          <div className="message__error">
                            {errors.required}
                          </div>
                        )}
                      </small>
                    </span>
                  </div>
                  {/* type */}
                  <div className="document__field">
                    <label>Type</label>
                    <span id="val">
                      <Select
                        className="select__type"
                        value={values.type}
                        onChange={event =>
                          setFieldValue('type', event.target.value)
                        }
                      >
                        <MenuItem key={'Contract'} value={'contract'}>
                          Contract
                        </MenuItem>
                        <MenuItem key={'Quote'} value={'quote'}>
                          Quote
                        </MenuItem>
                        <MenuItem key={'Guarantee'} value={'guarantee'}>
                          Guarantee
                        </MenuItem>
                      </Select>

                      <small>
                        {touched.type && errors.type && (
                          <div className="message__error">{errors.type}</div>
                        )}
                      </small>
                    </span>
                  </div>
                </div>
                <div className="box__file__upload">
                  <div className="box__upload__file">
                    <label>File</label>
                    <input
                      className="value__filed_file"
                      type="file"
                      id="images"
                      accept="application/pdf"
                      onChange={handleFileChangePdf}
                    />
                  </div>

                  <div>
                    {filePdfUpload && (
                      <iframe
                        src={filePdfUpload}
                        className="viewer__pdf"
                      ></iframe>
                    )}
                  </div>
                </div>

                <div className="box__buttons__json">
                  <Button
                    className="button__cancel__json__config"
                    onClick={() => onCloseDocumentForm()}
                    variant="outlined"
                  >
                    Cancel/Reset
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="button__save__json__config"
                  >
                    Save
                  </Button>
                </div>
                <DocumentJsonEditor
                  onClose={() => {
                    setShowDocumentJsonEditor(false);
                  }}
                  onSubmit={(documentConfiguration: IDocumentConfiguration) => {
                    setValues(documentConfiguration);
                  }}
                  open={showDocumentJsonEditor}
                  documentConfiguration={fileJson}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
