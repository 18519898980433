import { ICellRendererParams, RowNode } from "ag-grid-community";
import React from "react";
import { IDraft } from "../../../../interfaces/draft";
import Stack from "@mui/material/Stack";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { date } from "yup";

export const DraftActionCell = (
  { data }: ICellRendererParams<IDraft>,
  onClickReady: Function,
  onClickRevert: Function,
  onClickEdit: Function,
  onClickDelete: Function
) => {
  // TODO replace any
  const onClickEditEvent = (event: any) => {
    event.preventDefault();
    onClickEdit(data);
  };
  const onClickDelteEvent = (event: any) => {
    event.preventDefault();
    onClickDelete(data);
  };
  const onClickRevertEvent = (event: any) => {
    event.preventDefault();
    onClickRevert(data);
  };
  const onClickReadyEvent = (event: any) => {
    event.preventDefault();
    onClickReady(data);
  };
  return (
    <>
      <Stack direction="row" spacing={2}>
        {!data?.PublishedStatus && (
          <>
            {data?.ReadyToPublish ? (
              <IconButton
                sx={{ color: "#007DBB" }}
                onClick={onClickRevertEvent}
                aria-label="revert"
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ color: "#007DBB" }}
                onClick={onClickReadyEvent}
                aria-label="ready"
              >
                <CloudUploadIcon />
              </IconButton>
            )}
          </>
        )}
        {data?.DraftStatus === "Draft" &&
          data?.DBOperation !== "Delete" &&
          data.Type !== "TaskCode" && (
            <IconButton
              sx={{ color: "#007DBB" }}
              onClick={onClickEditEvent}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          )}
        {(data?.DraftStatus === "Draft" ||
          data?.DraftStatus === "Published") && (
          <IconButton
            sx={{ color: "#dc0464" }}
            onClick={onClickDelteEvent}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
};
