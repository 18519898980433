import { TaskCodeForm } from '../../taskCodePage/taskCodeForm/TaskCodeForm';
import { ModalMode } from '../../../../config/enums';
import { useEffect, useState } from 'react';
import { ITaskCode } from '../../../../interfaces/taskCode';
import { MarketingCopyForm } from '../marketingCopyForm/MarketingCopyForm';

interface IEditDraft {
  onCloseCreateEdit: Function;
  mode: ModalMode;
  rowData?: ITaskCode;
  taskCodeId?: string;
}

export const EditMarketing = ({
  rowData,
  onCloseCreateEdit,
  mode,
}: IEditDraft) => {
  return (
    <>
      {mode === ModalMode.ReadOnly && (
        <TaskCodeForm
          mode={mode}
          rowData={rowData}
          onCloseCreateEdit={onCloseCreateEdit}
        />
      )}
      {mode === ModalMode.Update && (
        <MarketingCopyForm
          rowData={rowData}
          onCloseCreateEdit={onCloseCreateEdit}
        ></MarketingCopyForm>
      )}
    </>
  );
};
