import { useEffect, useState } from 'react';
import { IPromotionModificationUser } from '../../interfaces/promotionModificationUser';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const usePromotion = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [promotion, setPromotion] = useState<IPromotionModificationUser[]>([]);
  const { setLoader } = useLoader();

  const getPromotion = async (branchId: number) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Promotion',
      requestConfig: {
        params: { branchId },
      },
    });
  };

  useEffect(() => {
    if (response?.recordset) {
      setPromotion(response?.recordset);
    }
  }, [response]);

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return { promotion, loading, getPromotion };
};
