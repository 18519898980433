import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import React from 'react';
import { theme } from './theme';

type Props = {
  children?: React.ReactNode;
};

export const AppTheme = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProvider>
  );
};
