import { useEffect, useState } from "react";
import { arsInfoManagementApi } from "../../service/arsInfoManagementApi";
import { useAxios } from "./useAxios";
import { useLoader } from "./useLoader";

export const useSchedulerHistoric = () => {
    const [response, error, loading, axiosFetch] = useAxios();
    const { setLoader } = useLoader();

    const getHistoricGTC = async (startRow: number, endRow: number) => {
        return await axiosFetch({
            axiosInstance: arsInfoManagementApi,
            method: 'get',
            url: 'ScheduledOperationLog',
            requestConfig: {
                params: { startRow, endRow },
            },
        });
    };
    const getSchedulerForId = async (scheduledOperationLogId: number) => {
        return await axiosFetch({
            axiosInstance: arsInfoManagementApi,
            method: 'get',
            url: 'ScheduledBranchOperationLog',
            requestConfig: {
                params: { scheduledOperationLogId },
            },
        });
    };

    useEffect(() => {
        setLoader(loading);
        return () => {
            setLoader(false);
        };
    }, [loading]);

    return {

        loading,
        getHistoricGTC, getSchedulerForId
    };
};
