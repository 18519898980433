import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import './styles.scss';
interface IDeleteConfirmation {
  open: boolean;
  onClose: Function;
  onHandleDelete: Function;
}
export const DeleteConfirmation = ({
  open,
  onClose,
  onHandleDelete,
}: IDeleteConfirmation) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="dialog__delete__confirm">
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog__delete">
          <div className="buttons__actions__delete">
            <Button
              onClick={() => onClose()}
              variant="outlined"
              className="button__cancel__delete__dialog"
            >
              Cancel
            </Button>
            <Button
              onClick={() => onHandleDelete()}
              variant="contained"
              className="button__delete__delete__dialog"
            >
              Delete
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
