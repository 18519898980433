import { Typography } from '@mui/material';
import { ArsLayout } from '../../layout/ArsLayout';

export const ProfilePage = () => {
  return (
    <ArsLayout>
      <Typography>Profile</Typography>
    </ArsLayout>
  );
};
