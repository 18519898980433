export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }) + ' - ' + date.toLocaleTimeString('en-US', {
        hour: '2-digit', minute: '2-digit'
    });
};

export const formatTime = (seconds: number | null): string => {
    if (seconds === null) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;

    return [hours, minutes, sec]
        .map(v => v < 10 ? "0" + v : v)
        .join(":");
};

export const generateTimes = () => {
    const times = ["No Selection"];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const timeFormatted = new Date(
                0,
                0,
                0,
                hour,
                minute
            ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });
            times.push(`${timeFormatted} CST`);
        }
    }
    return times;
};


export const formatBranches = (totalBranchIngested: number | null, branchesJson: string): string => {
    if (totalBranchIngested === null) return "0/0";

    try {
        const branches = JSON.parse(branchesJson);
        return `${totalBranchIngested}/${branches.length}`;
    } catch (e) {
        return "Invalid Data";
    }
};

export const findBranchWithName = (branchArray: any, branchID: number): string | undefined => {
    const foundBranch = branchArray.find((branch: { value: string; }) => branch.value === branchID.toString());
    return foundBranch ? foundBranch.label : undefined;
};