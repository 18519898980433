const CLIENTID = process.env.REACT_APP_MSAL_CLIENT_ID || '';
const AUTHORITY = process.env.REACT_APP_MSAL_AUTHORITY || '';
const REDIRECTURI = process.env.REACT_APP_MSAL_REDIRECT_URI || '';
const GRAPHMEENDPOINT = process.env.REACT_APP_GRAPHMEENDPOINT || '';

export const msalConfig = {
  auth: {
    clientId: CLIENTID,
    authority: AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: REDIRECTURI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`${process.env.REACT_APP_SCOPE_URI}`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: GRAPHMEENDPOINT,
};
