import { useEffect, useState } from 'react';
import { ModalMode } from '../../../config/enums';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ArsLayout } from '../../layout/ArsLayout';
import { PromotionForm } from './promotionForm/PromotionForm';
import { PromotionGrid } from './promotionGrid/PromotionGrid';
import { Dropdown } from '../../components/dropdown/Dropdown';
import './styles.scss';
import { IPromotion } from '../../../interfaces/promotion';
import { useRole } from '../../hooks/useRole';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { usePromotion } from '../../hooks/usePromotion';
import { DbOperation } from '../../../enums/DbOperation';
import { useDraftPromotion } from '../../hooks/useDraftPromotion';
import { toast } from 'react-toastify';
import { IPromotionDraft } from '../../../interfaces/promotionDraft';

export const PromotionPage = () => {
  const [openCreateEdit, setOpenCreateEdit] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IPromotion>();
  const [mode, setMode] = useState<ModalMode>(ModalMode.ReadOnly);
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const { roleBranchOptions } = useRole();
  const [selectedBranch, setSelectedBranch] = useState<number>(0);
  const { userADId, userName } = useRole();
  const { updatePromotionDraft, createPromotionDraft, getDraftByPromotionID } =
    useDraftPromotion();
  const { getPromotion } = usePromotion();

  const fetchPromotion = async () => {
    if (selectedBranch) {
      const { recordset } = await getPromotion(selectedBranch);
      setPromotions(recordset);
    }
  };

  useEffect(() => {
    fetchPromotion();
  }, [selectedBranch]);

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);

  const onClickAdd = () => {
    setMode(ModalMode.Create);
    setOpenCreateEdit(true);
  };
  const onClickEdit = (rowData: IPromotion) => {
    setMode(ModalMode.Update);
    setSelectedRow(rowData);
    setOpenCreateEdit(true);
  };
  const onClickDelete = (rowData: IPromotion) => {
    setSelectedRow(rowData);
    setOpenDeleteDialog(true);
  };
  const handlePromotionFormClose = () => {
    setSelectedRow(undefined);
    setOpenCreateEdit(false);
  };
  const handleDelete = async () => {
    if (!selectedRow?.id) {
      return;
    }
    const promotion: IPromotionDraft = {
      ...selectedRow,
      DBOperation: DbOperation.Delete,
      ModificationUser: userName,
      ModificationUserId: userADId,
    };
    const { recordset } = await getDraftByPromotionID(
      selectedRow?.id.toString(),
    );

    if (recordset.length === 0) {
      await createPromotionDraft(promotion);
    } else {
      promotion.DraftID = recordset[0].DraftID;
      await updatePromotionDraft(promotion);
    }
    toast.success('Promotion removal Draft created');
    // await deleteTaskCode(selectedRow);
    setSelectedRow(undefined);
    setOpenDeleteDialog(false);
  };
  const handleBranchSelected = (branch: number) => {
    setSelectedBranch(branch);
  };

  return (
    <ArsLayout>
      {openCreateEdit ? (
        <PromotionForm
          mode={mode}
          rowData={selectedRow}
          onCloseCreateEdit={handlePromotionFormClose}
        />
      ) : (
        <>
          <div>
            <h1>Promotion</h1>
          </div>
          <div className="grid__header__promo">
            <Dropdown
              onChange={handleBranchSelected}
              options={roleBranchOptions}
              value={selectedBranch}
              label="Branch ID"
            />
            <Button
              onClick={() => onClickAdd()}
              variant="outlined"
              className="button__add__promo"
              startIcon={<AddCircleOutlineIcon />}
            >
              Add New Promotion
            </Button>
          </div>
          <PromotionGrid
            promotions={promotions}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        </>
      )}
      <DeleteConfirmation
        open={openDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </ArsLayout>
  );
};
