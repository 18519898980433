import { useEffect } from 'react';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';
import { BlobServiceClient } from '@azure/storage-blob';
import { DbOperation } from '../../enums/DbOperation';
import { IBlobMetadata } from '../../interfaces/blobMetadata';
import { AzureFolderContainer } from '../../enums/AzureFolderContainer';
import { useRole } from './useRole';
import { blobToBase64, blobToString } from './../../helpers/blobHelper';

export const useDocument = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const { userADId, userName } = useRole();
  const accountName = process.env.REACT_APP_AZURE_ACCOUNT;
  const sas = process.env.REACT_APP_AZURE_SAS;
  const storageBlobEndpoint = `https://${accountName}.blob.core.windows.net`;
  const blobServiceClient = new BlobServiceClient(
    `${storageBlobEndpoint}${sas}`,
  );
  const { setLoader } = useLoader();

  const getDocuments = async (branchId: number) => {
    const { recordset: documents } = await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'Document',
      requestConfig: {
        params: { branchId },
      },
    });
    return documents;
  };
  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  const uploadDraftBlobPdf = async (
    branchId: number,
    file: any,
    documentName: string,
    dbOperation: DbOperation,
  ) => {
    if (!file) {
      return false;
    }
    const draftPath = `branch-${branchId}/${AzureFolderContainer.FolderContracts}/${documentName}.pdf`;

    const metadata: IBlobMetadata = {
      dboperation: dbOperation,
      publishedstatus: '0',
      readytopublish: '0',
      publishdate: '',
      publishderscription: '',
      modificationuser: userName,
      modificationuserid: userADId,
    };
    const containerName = AzureFolderContainer.ContainerPublisherDrafts;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(draftPath);
    try {
      setLoader(true);
      await blockBlobClient.upload(file, file.size, {
        metadata,
      });
      return true;
    } catch (error: any) {
      console.log(error.message);
      return false;
    } finally {
      setLoader(false);
    }
  };

  const uploadDraftBlobJson = async (
    branchId: number,
    documentObject: Object,
    documentName: string,
    dbOperation: DbOperation,
  ) => {
    if (!documentObject) {
      return false;
    }
    const draftPath = `branch-${branchId}/${AzureFolderContainer.FolderContracts}/${documentName}.json`;
    const metadata: IBlobMetadata = {
      dboperation: dbOperation,
      publishedstatus: '0',
      readytopublish: '0',
      publishdate: '',
      publishderscription: '',
      modificationuser: userName,
      modificationuserid: userADId,
    };
    const containerName = AzureFolderContainer.ContainerPublisherDrafts;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(draftPath);
    try {
      setLoader(true);

      const documentJson = JSON.stringify(documentObject);
      await blockBlobClient.upload(documentJson, documentJson.length, {
        metadata,
      });
      return true;
    } catch (error: any) {
      console.log(error.message);
      return false;
    } finally {
      setLoader(false);
    }
  };
  const downloadBlobPdf = async (branchId: number, blobName: any) => {
    setLoader(true);
    const blobNameComplete: any = `${blobName}.pdf`;
    const containerName = `branch-${branchId}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobNameComplete);
    try {
      const download: any = await blobClient.download();
      const downloaded: any = await blobToBase64(await download.blobBody);
      let validate = downloaded.indexOf('octet');
      if (validate !== -1)
        return downloaded.replace(
          'application/octet-stream',
          'application/pdf',
        );
      else return downloaded;
    } catch (error) {
      console.log('PDF file do not exist');
    } finally {
      setLoader(false);
    }
  };

  const downloadBlobJson = async (branchId: number, blobName: any) => {
    setLoader(true);
    const blobNameComplete: any = `${blobName}.json`;
    const containerName = `branch-${branchId}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobNameComplete);
    let downloaded;
    try {
      const download: any = await blobClient.download();
      downloaded = await blobToString(await download.blobBody);
    } catch (error) {
      console.log('Json file do not exist');
    } finally {
      setLoader(false);
    }
    return downloaded;
  };

  const downloadDraftPdf = async (blobPath: string) => {
    setLoader(true);
    const blobNameComplete: any = `${blobPath}.pdf`;
    const containerName = AzureFolderContainer.ContainerPublisherDrafts;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobNameComplete);
    try {
      const download: any = await blobClient.download();
      const downloaded: any = await blobToBase64(await download.blobBody);
      let validate = downloaded.indexOf('octet');
      if (validate !== -1)
        return downloaded.replace(
          'application/octet-stream',
          'application/pdf',
        );
      else return downloaded;
    } catch (error) {
      console.log('PDF file do not exist');
    } finally {
      setLoader(false);
    }
  };
  const downloadDraftJson = async (blobPath: string) => {
    setLoader(true);
    const blobNameComplete: any = `${blobPath}.json`;
    const containerName = AzureFolderContainer.ContainerPublisherDrafts;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobNameComplete);
    try {
      const download: any = await blobClient.download();
      const downloaded = await blobToString(await download.blobBody);
      return downloaded;
    } catch (error) {
      console.log('Json file do not exist');
    } finally {
      setLoader(false);
    }
  };
  const documentExist = async (blobPath: string, branchId: number) => {
    const containerName = `branch-${branchId}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(
      `contracts/${blobPath}.json`,
    );
    return await blobClient.exists();
  };

  const getMetadata = async (blobNameComplete: string) => {
    const containerName = AzureFolderContainer.ContainerPublisherDrafts;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobNameComplete);
    const { metadata } = await blobClient.getProperties();
    return metadata;
  };

  return {
    getDocuments,
    uploadDraftBlobPdf,
    uploadDraftBlobJson,
    downloadBlobJson,
    downloadBlobPdf,
    downloadDraftPdf,
    downloadDraftJson,
    documentExist,
    getMetadata,
  };
};
