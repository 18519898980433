import { Box, Button, Modal } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import { ModalMode } from '../../../../config/enums';
import { IComponent } from '../../../../interfaces/taskCode';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { GridActionCell } from '../../../components/GridActionCell';
import { ComponentForm } from '../componentForm/ComponentForm';
import './styles.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const initialComponent: IComponent = {
  Name: '',
  Picture: '',
  Make: '',
  Model: '',
  UniqueID: '',
  MarketingName: '',
};
interface ComponentsGridInferface {
  onChange: Function;
  onBlur: Function;
  value: IComponent[];
  taskCode?: string;
  branchID?: number;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const ComponentsGrid = ({
  onChange,
  onBlur,
  value = [],
  taskCode,
  branchID,
}: ComponentsGridInferface) => {
  const [openCreateEditModal, setOpenCreateEditModal] = useState(false);
  const [mode, setMode] = useState(ModalMode.ReadOnly);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(initialComponent);
  const [components, setComponents] = useState(value ?? []);

  const onClickAdd = (event: any) => {
    event.preventDefault();
    setMode(ModalMode.Create);
    setSelectedRow(initialComponent);
    setOpenCreateEditModal(true);
  };
  const onClickEdit = (rowData: IComponent) => {
    setMode(ModalMode.Update);
    setSelectedRow(rowData);
    setOpenCreateEditModal(true);
  };
  const onClickDelete = (rowData: IComponent) => {
    setSelectedRow(rowData);
    setOpenDeleteDialog(true);
  };
  const handleOnSubmit = (newComponent: IComponent) => {
    if (mode === ModalMode.Create) {
      let editedComponents = [...components, newComponent];
      setComponents(editedComponents);
      onChange(editedComponents);
    }
    if (mode === ModalMode.Update) {
      const editedComponents = components.map((component: IComponent) => {
        if (component.UniqueID === newComponent.UniqueID) {
          return newComponent;
        }
        return component;
      });
      setComponents(editedComponents);
      onChange(editedComponents);
    }

    setOpenCreateEditModal(false);
  };

  const handleDelete = () => {
    const filteredComponents = components.filter(
      (component: IComponent) => component.UniqueID !== selectedRow.UniqueID,
    );
    setComponents(filteredComponents);
    onChange(filteredComponents);
    setOpenDeleteDialog(false);
  };

  const handleCellRenderer = (data: any) => {
    return GridActionCell(
      data,
      onClickEdit,
      onClickDelete,
      onClickEdit,
      'promo',
    );
  };
  const columnsDefinition: any[] = [
    { field: 'Name', headerName: 'Name', filter: true, resizable: true },
    { field: 'Picture', headerName: 'Picture', filter: true, resizable: true },
    { field: 'Make', headerName: 'Make', filter: true, resizable: true },
    { field: 'Model', headerName: 'Model', filter: true, resizable: true },
    {
      field: 'UniqueID',
      headerName: 'ResourceUniqueID',
      filter: true,
      resizable: true,
    },
    {
      field: 'MarketingDescription1',
      headerName: 'MarketingDescription1',
      minWidth: 250,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Edit Component',
      minWidth: 50,
      cellRenderer: handleCellRenderer,
      editable: false,
      colId: 'action',
      pinned: 'right',
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnsDefinition);

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%', height: 250 }}>
        <AgGridReact columnDefs={columnDefs} rowData={components} />
      </div>

      <Modal
        open={openCreateEditModal}
        onClose={() => setOpenCreateEditModal(false)}
      >
        <Box className="modal">
          <ComponentForm
            value={selectedRow}
            mode={mode}
            onSubmit={handleOnSubmit}
            components={components}
            taskCode={taskCode}
            branchID={branchID}
            setOpenCreateEditModal={setOpenCreateEditModal}
          />

          <Button
            onClick={() => setOpenCreateEditModal(false)}
            variant="outlined"
            className="button__close__component"
          >
            Close
          </Button>
        </Box>
      </Modal>
      <DeleteConfirmation
        open={openDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};
