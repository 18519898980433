import { createContext } from 'react';

interface IAuthContext {
  token: string;
  setToken: Function;
}

export default createContext<IAuthContext>({
  token: '',
  setToken: () => { },
});
