import React, { useState, useEffect } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  CircularProgress,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../spinner/Spinner";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import LoginIcon from "@mui/icons-material/Login";

interface NavBarProps {
  sideBarWidth?: number;
  onLogout?: () => void;
}

export const NavBar: React.FC<NavBarProps> = ({
  sideBarWidth = 300,
  onLogout,
}) => {
  const [username, setUsername] = useState<string | null>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [account] = accounts;
  const userNameAzure = account?.name?.split(" ")[0];

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    setUsername(savedUsername);
  }, []);

  const handleLogout = () => {
    setIsLoggingOut(true);
    setTimeout(() => {
      localStorage.removeItem("username");
      setIsLoggingOut(false);
      localStorage.setItem("isAuthenticated", "false");
      if (onLogout) {
        onLogout();
      }
      navigate("/login");
    }, 3000);
  };

  const handleLogoutAzure = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/logout",
    });
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        ml: { sm: `${sideBarWidth}px` },
        color: "black",
        backgroundColor: "white",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Toolbar className="navbar">
        <div className="auth-container">
          {username ? (
            <div style={{ display: "flex" }}>
              <span className="user__name">{username}</span>
              {isLoggingOut ? (
                <CircularProgress />
              ) : (
                <IconButton
                  sx={{
                    color: "black",
                    fontSize: "large",
                    marginTop: "-0.3rem",
                  }}
                  onClick={handleLogout}
                  aria-label="logout"
                >
                  Logout <LoginIcon />
                </IconButton>
              )}
            </div>
          ) : (
            <>
              <AuthenticatedTemplate>
                <span className="user__name">{userNameAzure}</span>
                <IconButton
                  sx={{
                    color: "black",
                    fontSize: "large",
                    marginTop: "-0.3rem",
                  }}
                  onClick={handleLogoutAzure}
                  aria-label="delete"
                >
                  Logout
                  <LoginIcon />
                </IconButton>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Button onClick={handleLogin} color="inherit">
                  Login
                </Button>
              </UnauthenticatedTemplate>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
