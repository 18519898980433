import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from './useAuth';
import { useRole } from './useRole';

export const useAxios = () => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [controller, setController] = useState<AbortController>();
  const { token } = useAuth();

  const axiosFetch = async ({
    axiosInstance,
    method,
    url,
    requestConfig = {},
  }: {
    axiosInstance: AxiosInstance;
    method: string;
    url: string;
    requestConfig: AxiosRequestConfig;
  }) => {
    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance.request({
        ...requestConfig,
        method: method.toLocaleLowerCase(),
        url,
        headers: { Authorization: token },
        // signal: ctrl.signal,
      });
      setResponse(res.data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [response, error, loading, axiosFetch] as const;
};
