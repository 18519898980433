import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ArsLayout } from "../../layout/ArsLayout";
import { useSchedulerGTC } from "../../hooks/useSchedulerGTC";
import { useSchedulerHistoric } from "../../hooks/useSchedulerHistoric";
import { toast } from "react-toastify";
import {
  formatDate,
  formatTime,
  generateTimes,
  formatBranches,
  findBranchWithName,
} from "../../../helpers/formatHelper";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import { SchedulerData } from "../../../interfaces/schedulerData";
import { CompletedUpdate } from "../../../interfaces/completedUpdate";
import { useRole } from "../../hooks/useRole";
import { useSchedulerDay } from "../../hooks/useSchedulerDay";

export const SchedulerGTC: React.FC = () => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const { getSchedulerDay } = useSchedulerDay();
  const { createSchedulerGTC } = useSchedulerGTC();
  const { getHistoricGTC, getSchedulerForId } = useSchedulerHistoric();
  const [selectedTime, setSelectedTime] = useState("No Selection");
  const [completedUpdatesData, setCompletedUpdatesData] = useState<
    CompletedUpdate[]
  >([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [schedulerData, setSchedulerData] = useState<SchedulerData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 10;
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);
  const { roleBranchOptions } = useRole();

  useEffect(() => {
    getSechdulerDay();
    getAllDates();
  }, [currentPage]);

  const getAllDates = async () => {
    try {
      const offset = (currentPage - 1) * recordsPerPage;
      const { scheduleLog, total } = await getHistoricGTC(
        offset,
        offset + recordsPerPage
      );
      setTotalData(total);

      setTotalPages(Math.ceil(total / recordsPerPage));
      setCompletedUpdatesData(scheduleLog);
    } catch (error) {
      toast.error("Error Returning Data");
    }
  };

  const getSechduler = async (idDate: any) => {
    const { scheduleBranchLog } = await getSchedulerForId(idDate);
    setSchedulerData(scheduleBranchLog);
  };
  const getSechdulerDay = async () => {
    try {
      const daySaved = await getSchedulerDay();

      const timeString = daySaved[0].time;
      if (timeString != null) {
        const time = new Date();
        const [hours, minutes] = timeString.split(":").map(Number);
        time.setHours(hours, minutes, 0);
        const formattedTime =
          time.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }) + " CST";

        setSelectedTime(formattedTime);
      }
    } catch {
      toast.error("Error Returning Data");
    }
  };

  const times = generateTimes();

  const handleRowClick = async (id: number) => {
    if (expandedRowId === id) {
      setExpandedRowId(null);
    } else {
      setExpandedRowId(id);
      getSechduler(id);
    }
  };

  const handleSave = async () => {
    const schedule = days.map((day) => {
      let formattedTime = null;
      if (selectedTime !== "No Selection") {
        const [time, period] = selectedTime.split(" ");
        const [hours12, minutes] = time.split(":").map(Number);
        const hours24 = period === "PM" ? (hours12 % 12) + 12 : hours12 % 12;
        formattedTime = `${hours24.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:00`;
      }
      return { day, time: formattedTime };
    });
    await createSchedulerGTC(schedule);
    toast.success("GTC scheduler updated");
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const currentRecords = completedUpdatesData.slice(0, recordsPerPage);
  return (
    <ArsLayout>
      <div className="scheduler-header">
        <h1>GTC Scheduler</h1>
        <p>
          Schedule GTC codes to be sent out anytime during the week. They can be
          scheduled multiple times a week if needed. This process will be
          executed for all branches at the selected time.
        </p>
      </div>
      <div className="gtc-scheduler">
        <div className="scheduler-form">
          <div className="form-row">
            <label htmlFor="timeSelection" className="day-label">
              Daily
            </label>
            <select
              id="timeSelection"
              name="timeSelection"
              className="time-select"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              {times.map((time, timeIndex) => (
                <option key={timeIndex} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <button className={"save-button"} onClick={handleSave}>
            SAVE
          </button>
        </div>
      </div>
      <div className="completed-updates-container">
        <h2>Completed Updates</h2>
        <table className="completed-updates-table">
          <thead>
            <tr>
              <th style={{ width: "10rem" }}>Date</th>
              <th>Status</th>
              <th>Additions</th>
              <th>Modifications</th>
              <th>Deletions</th>
              <th>Branches</th>
              <th>Time Duration</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((update, index) => {
              const rowClass =
                index % 2 === 0 ? "white-background" : "gray-background";
              let statusClass = "";
              switch (update.Status) {
                case "COMPLETED":
                  statusClass = "complete";
                  break;
                case "PARTIAL":
                  statusClass = "partial";
                  break;
                case "FAILED":
                  statusClass = "failed";
                  break;
                case "IN PROGRESS":
                  statusClass = "progress";
                  break;
                default:
                  statusClass = "";
              }
              return (
                <React.Fragment key={index}>
                  <tr onClick={() => handleRowClick(update.ID)}>
                    <td>
                      <div className="date-cell">
                        <div>
                          {expandedRowId === update.ID ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>

                        <div className="date-text">
                          {formatDate(update.Date)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className={`status-label ${statusClass}`}>
                        {update.Status || "-"}
                      </span>
                    </td>
                    <td>{update.TotalInserted || 0}</td>
                    <td>{update.TotalUpdated || 0}</td>
                    <td>{update.TotalDeleted || 0}</td>
                    <td>
                      {formatBranches(
                        update.TotalBranchIngested,
                        update.Branches
                      )}
                    </td>
                    <td>{formatTime(update.ExecutionTimeInSeconds)}</td>
                  </tr>
                  {expandedRowId === update.ID &&
                    schedulerData.map((dataItem, index) => (
                      <tr key={index} className={rowClass}>
                        <td></td>
                        <td>
                          {dataItem.Status === "COMPLETED" ? (
                            <CheckIcon />
                          ) : (
                            <span
                              className={
                                dataItem.Status !== "COMPLETED" ? "failed" : ""
                              }
                            >
                              FAILED
                            </span>
                          )}
                        </td>
                        <td>{dataItem.TotalInserted || "-"}</td>
                        <td>{dataItem.TotalUpdated || "-"}</td>
                        <td>{dataItem.TotalDeleted || "-"}</td>
                        <td style={{ fontWeight: "bold" }}>
                          {findBranchWithName(
                            roleBranchOptions,
                            dataItem.BranchID
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-container">
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>

          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || totalData < 5}
          >
            Next
          </button>
        </div>
      </div>
    </ArsLayout>
  );
};
