import { useEffect, useState } from 'react';
import { ITaskCodeModificationUser } from '../../interfaces/taskCodeModificationUser';
import { arsInfoManagementApi } from '../../service/arsInfoManagementApi';
import { useAxios } from './useAxios';
import { useLoader } from './useLoader';

export const useGtc = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const [taskCodes, setTaskCodes] = useState<ITaskCodeModificationUser[]>([]);
  const { setLoader } = useLoader();

  const getTaskCodes = async (
    branchId: number,
    globalFilter: string,
    startRow: number,
    endRow: number,
  ) => {
    return await axiosFetch({
      axiosInstance: arsInfoManagementApi,
      method: 'get',
      url: 'TaskCode',
      requestConfig: {
        params: { branchId, globalFilter, startRow, endRow },
      },
    });
  };

  useEffect(() => {
    if (response?.recordset) {
      setTaskCodes(response?.recordset);
    }
  }, [response]);

  useEffect(() => {
    setLoader(loading);
    return () => {
      setLoader(false);
    };
  }, [loading]);

  return { taskCodes, loading, getTaskCodes };
};
