import { useEffect, useState } from 'react';
import { ModalMode } from '../../../config/enums';
import { IFinancingOffer } from '../../../interfaces/financingOffer';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ArsLayout } from '../../layout/ArsLayout';
import { FinancingOfferForm } from './financingOfferForm/FinancingOfferForm';
import { FinancingOfferGrid } from './financingOfferGrid/FinancingOfferGrid';
import { Dropdown } from '../../components/dropdown/Dropdown';
import './styles.scss';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useRole } from '../../hooks/useRole';
import { useFinancingOffers } from '../../hooks/useFinancingOffers';
import { DbOperation } from '../../../enums/DbOperation';
import { useDraftFinancing } from '../../hooks/useDraftFinancing';
import { toast } from 'react-toastify';
import { IFinancingOfferDraft } from '../../../interfaces/financingDraft';

export const FinancingOfferPage = () => {
  const { updateFinancingDraft, createFinancingDraft, getDraftByFinancingID } =
    useDraftFinancing();
  const [openCreateEdit, setOpenCreateEdit] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IFinancingOffer>();
  const [mode, setMode] = useState<ModalMode>(ModalMode.ReadOnly);
  const [financingOffers, setFinancingOffers] = useState<IFinancingOffer[]>([]);
  const { roleBranchOptions } = useRole();
  const { getFinancing } = useFinancingOffers();
  const [selectedBranch, setSelectedBranch] = useState<number>(0);
  const { userADId, userName } = useRole();

  const fetchFinancing = async () => {
    if (selectedBranch) {
      const { recordset } = await getFinancing(selectedBranch);
      setFinancingOffers(recordset);
    }
  };

  useEffect(() => {
    fetchFinancing();
  }, [selectedBranch]);

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);

  const onClickAdd = () => {
    setMode(ModalMode.Create);
    setOpenCreateEdit(true);
  };
  const onClickEdit = (rowData: IFinancingOffer) => {
    setMode(ModalMode.Update);
    setSelectedRow(rowData);
    setOpenCreateEdit(true);
  };
  const onClickDelete = (rowData: IFinancingOffer) => {
    setSelectedRow(rowData);
    setOpenDeleteDialog(true);
  };
  const handleFinancingOfferFormClose = () => {
    setSelectedRow(undefined);
    setOpenCreateEdit(false);
  };
  const handleDelete = async () => {
    if (!selectedRow?.ID) {
      return;
    }
    const financing: IFinancingOfferDraft = {
      ...selectedRow,
      DBOperation: DbOperation.Delete,
      ModificationUser: userName,
      ModificationUserId: userADId,
    };
    const { recordset } = await getDraftByFinancingID(String(selectedRow?.ID));

    if (recordset.length === 0) {
      await createFinancingDraft(financing);
    } else {
      financing.DraftID = recordset[0].DraftID;
      await updateFinancingDraft(financing);
    }
    toast.success('Financing removal Draft created');
    setSelectedRow(undefined);
    setOpenDeleteDialog(false);
  };
  const handleBranchSelected = (branch: number) => {
    setSelectedBranch(branch);
  };

  return (
    <ArsLayout>
      {openCreateEdit ? (
        <FinancingOfferForm
          mode={mode}
          rowData={selectedRow}
          onCloseCreateEdit={handleFinancingOfferFormClose}
        />
      ) : (
        <>
          <div>
            <h1>Financing Offer Managment</h1>
          </div>
          <div className="grid__header__financing">
            <Dropdown
              onChange={handleBranchSelected}
              options={roleBranchOptions}
              value={selectedBranch}
              label="Branch ID"
            />
            <Button
              onClick={() => onClickAdd()}
              variant="outlined"
              className="button__add__financing"
              startIcon={<AddCircleOutlineIcon />}
            >
              Add New Offer
            </Button>
          </div>
          <FinancingOfferGrid
            financingOffers={financingOffers}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        </>
      )}
      <DeleteConfirmation
        open={openDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </ArsLayout>
  );
};
