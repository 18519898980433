import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import './styles.scss';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { fontSize } from '@mui/system';

interface IMultiselect {
  onChange: Function;
  value: string[] | number[];
  type: string;
}
export const MultiselecInput = ({ onChange, value, type }: IMultiselect) => {
  const [data, setData] = useState('');
  const [values, setValues] = useState<any[]>([]);
  let id = 0;

  useEffect(() => {
    if (value) {
      const castedValues = value.map(i => castValue(i.toString()));
      setValues(castedValues);
    }
  }, []);

  const castValue = (stringValue: string) => {
    switch (type) {
      case 'number':
        return Number(stringValue);
      case 'string':
        return stringValue;
      default:
        break;
    }
  };

  const handleChangeAdd = () => {
    if (data !== '') {
      let valueSend = [...values, castValue(data)];
      setValues(valueSend);
      onChange(valueSend);
    }
    setData('');
  };
  const handleChangeRemove = (val: any) => {
    const index = values.indexOf(val);
    if (index > -1) {
      values.splice(index, 1);
      onChange(values);
    }
  };

  return (
    <>
      <div className="multiselect__section">
        <div className="selected__container">
          {values.map((val: any) => (
            <div className="selected" key={val}>
              <small>{val}</small>
              <IconButton
                className="button__delete__multi"
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => handleChangeRemove(val)}
              >
                <CloseIcon sx={{ fontSize: '11px', color: 'red' }} />
              </IconButton>
            </div>
          ))}
        </div>
        <input
          className="multiselect__input"
          type={type}
          value={data}
          onChange={e => setData(e.target.value.toString())}
        />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={handleChangeAdd}
        >
          <AddIcon />
        </IconButton>
      </div>
    </>
  );
};
