import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IDocumentConfiguration } from '../../../../interfaces/documentJson';
import { Draft07 } from 'json-schema-library';
import * as jsonSchema from './../../../../map/jsonSchema.json';

import './styles.scss';
import { toast } from 'react-toastify';
interface IDocumentJsonEditor {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  documentConfiguration: IDocumentConfiguration;
}
const schema = new Draft07(jsonSchema);

export const DocumentJsonEditor = ({
  open,
  onClose,
  onSubmit,
  documentConfiguration,
}: IDocumentJsonEditor) => {
  const [jsonText, setJsonText] = useState('');
  useEffect(() => {
    setJsonText(JSON.stringify(documentConfiguration, null, 2));
  }, []);

  const handleFormat = () => {
    setJsonText(value => {
      try {
        return JSON.stringify(JSON.parse(value), null, 2);
      } catch (error) {
        toast.error('Invalid json');
        return value;
      }
    });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    setJsonText(value);
  };
  const handleSubmit = () => {
    try {
      JSON.parse(jsonText);
    } catch (error) {
      toast.error('Invalid json');
      return false;
    }
    const documentConfigurationObject = JSON.parse(jsonText);
    const error = schema.validate(documentConfigurationObject);
    if (error.length > 0) {
      toast.error('Invalid configuration');
      console.log(`Incorrect json format : ${JSON.stringify(error)}`);
      return false;
    }
    onSubmit(documentConfigurationObject);
    onClose();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
    >
      <div className="">
        <DialogTitle>Edit document json</DialogTitle>
        <DialogContent>
          <Button onClick={handleFormat}>Format</Button>
          <textarea
            className="jsonEditor"
            placeholder="Paste the JSON here"
            onChange={handleChange}
            value={jsonText}
          />
        </DialogContent>
        <DialogActions className="">
          <div className="buttons__json__edit">
            <Button
              onClick={() => onClose()}
              variant="outlined"
              className="button__cancel__json"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              className="button__save__json"
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
