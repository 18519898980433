import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalMode } from '../../../config/enums';
import { IDocument } from '../../../interfaces/document';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { useAzureStorage } from '../../hooks/useAzureStorage';
import { useDocument } from '../../hooks/useDocument';
import { useRole } from '../../hooks/useRole';
import { ArsLayout } from '../../layout/ArsLayout';
import { DocumentForm } from './documentForm/DocumentForm';
import { DocumentGrid } from './documentGrid/DocumentGrid';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import './styles.scss';

export const DocumentPage = () => {
  const [showDocumentForm, setShowDocumentForm] = useState(false);

  const [selectedRow, setSelectedRow] = useState<IDocument>();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { roleBranchOptions } = useRole();
  const [selectedBranch, setSelectedBranch] = useState<number>(0);

  const { createDeleteDraftBlob } = useAzureStorage();
  const [mode, setMode] = useState<ModalMode>(ModalMode.ReadOnly);
  const { getDocuments } = useDocument();

  useEffect(() => {
    if (selectedBranch) {
      getBlobsByBranch(selectedBranch);
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (roleBranchOptions.length > 0) {
      setSelectedBranch(Number(roleBranchOptions[0].value));
    }
  }, [roleBranchOptions]);

  const getBlobsByBranch = async (branchId: number) => {
    const blobs = await getDocuments(branchId);
    setDocuments(blobs);
  };

  const handleBranchSelected = (branchId: number) => {
    setSelectedBranch(Number(branchId));
    getBlobsByBranch(Number(branchId));
  };

  const handleCloseDocumentForm = () => {
    setShowDocumentForm(false);
    setSelectedRow(undefined);
  };
  const handleDelete = async () => {
    if (!selectedRow) {
      return;
    }
    await createDeleteDraftBlob(selectedRow.Path, selectedBranch);
    setSelectedRow(undefined);
    setShowDeleteDialog(false);
  };
  const onClickAdd = () => {
    setMode(ModalMode.Create);
    setShowDocumentForm(true);
  };

  const onClickCellEdit = (rowData: IDocument) => {
    setMode(ModalMode.Update);
    setSelectedRow(rowData);
    setShowDocumentForm(true);
  };
  const onClickCellDelete = (rowData: IDocument) => {
    setSelectedRow(rowData);
    setShowDeleteDialog(true);
  };

  return (
    <ArsLayout>
      {!showDocumentForm ? (
        <>
          <div>
            <h1>PDF Documents</h1>
          </div>
          <div className="dropdown__branch">
            <Dropdown
              onChange={handleBranchSelected}
              options={roleBranchOptions}
              value={selectedBranch}
              label="Branch"
            />
          </div>
          <div className="add">
            <Button
              variant="outlined"
              onClick={onClickAdd}
              className="button__add__document"
              startIcon={<AddCircleOutlineTwoToneIcon />}
            >
              Create Document
            </Button>
          </div>

          <DocumentGrid
            documents={documents}
            onClickEdit={onClickCellEdit}
            onClickDelete={onClickCellDelete}
          />
        </>
      ) : (
        <DocumentForm
          onCloseDocumentForm={handleCloseDocumentForm}
          rowData={selectedRow}
          branchId={selectedBranch}
          mode={mode}
          callFromDashbord={false}
        />
      )}
      <DeleteConfirmation
        open={showDeleteDialog}
        onHandleDelete={handleDelete}
        onClose={() => setShowDeleteDialog(false)}
      />
    </ArsLayout>
  );
};
