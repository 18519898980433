import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState, useCallback } from 'react';
import { loginRequest } from '../../authConfig';
import { InteractionStatus } from '@azure/msal-browser';
import AuthContext from '../../ars/context/AuthContext';

interface IAzureAuth {
  children: any;
}

export const AzureAuth = ({ children }: IAzureAuth) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [lastInteractionStatus, setLastInteractionStatus] = useState('');
  const [token, setToken] = useState<string | null>(null);

  // Get the account, ensuring we're safely accessing the array
  const account = accounts.length > 0 ? accounts[0] : null;

  const getToken = useCallback(async () => {
    if (!account) {
      console.error('No account found.');
      return;
    }

    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      const bearer = `Bearer ${tokenResponse.accessToken}`;
      setToken(bearer);
    } catch (err) {
      console.error('Error acquiring token silently:', err);
      // Consider adding a fallback to interactive method or other error handling
    }
  }, [account, instance]);

  useEffect(() => {
    setLastInteractionStatus(inProgress);

    if (
      inProgress === InteractionStatus.None &&
      lastInteractionStatus !== InteractionStatus.HandleRedirect &&
      !isAuthenticated
    ) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    } else if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, inProgress, getToken, instance, lastInteractionStatus]);

  return (
    <>
      {token && (
        <AuthContext.Provider value={{ token, setToken }}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};
