import { ICellRendererParams } from 'ag-grid-community'
import React, { useEffect, useState } from 'react'
import { DraftStatus } from '../../../../enums/DraftStatus'
import { IDraft } from '../../../../interfaces/draft'
function getNextMonday(date: Date) {
  const dateCopy = new Date(date.getTime())
  const nextMonday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
    ),
  )

  return nextMonday
}

export const DateCellRenderer = ({ data }: ICellRendererParams<IDraft>) => {
  const [date, setDate] = useState('')
  useEffect(() => {
    if (data?.DraftStatus === DraftStatus.Scheduled) {
      let shecheduleDateAndTime = new Date()
      shecheduleDateAndTime.setDate(shecheduleDateAndTime.getDate() + 1)
      if (
        shecheduleDateAndTime.getDay() === 6 ||
        shecheduleDateAndTime.getDay() === 7
      ) {
        shecheduleDateAndTime = getNextMonday(shecheduleDateAndTime)
      }
      shecheduleDateAndTime.setHours(1, 0, 0)
      setDate(
        `${shecheduleDateAndTime.toLocaleDateString()} ${shecheduleDateAndTime.toLocaleTimeString()}`,
      )
    }
    if (data?.DraftStatus === DraftStatus.Draft) {
      let draftDate = new Date(data.DraftModificationDate)
      setDate(
        `${draftDate.toLocaleDateString()} ${draftDate.toLocaleTimeString()}`,
      )
    }
    if (data?.DraftStatus === DraftStatus.Published) {
      let draftDate = new Date(data.PublishDate)
      setDate(
        `${draftDate.toLocaleDateString()} ${draftDate.toLocaleTimeString()}`,
      )
    }
  }, [])

  return <>{date}</>
}
