import { Route, Routes } from "react-router-dom";
import { ArsRoutes } from "../ars/routes/ArsRoutes";
import { LogoutPage } from "../auth/pages/LogoutPage";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { AzureAuth } from "../auth/context/AzureAuth";
import { useEffect, useState } from "react";
import { LoginPage } from "../ars/pages/loginPage/loginPage";
import { ArsRoutesScheduler } from "../ars/routes/ArsRoutesScheduler";
import { SchedulerGTC } from "../ars/pages/schedulerGTC/SchedulerGTC";

export const AppRouter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (isLoggedIn: boolean) => {
    setIsAuthenticated(isLoggedIn);
    localStorage.setItem("isAuthenticated", isLoggedIn.toString());
  };

  return (
    <Routes>
      {/* Login */}
      <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
      {isAuthenticated ? (
        <Route path="/scheduler" element={<SchedulerGTC />} />
      ) : (
        <>
          {" "}
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        </>
      )}

      {/* Ars */}
      <Route
        path="/*"
        element={
          <AzureAuth>
            <AuthenticatedTemplate>
              <ArsRoutes />
            </AuthenticatedTemplate>
          </AzureAuth>
        }
      />
    </Routes>
  );
};
