import { Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ModalMode } from '../../../../config/enums';
import { DbOperation } from '../../../../enums/DbOperation';
import { handleNumericChange } from '../../../../helpers/helper';
import { IComponent, ITaskCode } from '../../../../interfaces/taskCode';
import { ITaskCodeDraft } from '../../../../interfaces/taskCodeDraft';
import { useDraftGtc } from '../../../hooks/useDraftGtc';
import { useRole } from '../../../hooks/useRole';
import { ComponentsGrid } from '../componentsGrid/ComponentsGrid';
import { toast } from 'react-toastify';
import './styles.scss';

interface ITaskCodeForm {
  mode: ModalMode;
  rowData?: ITaskCode;
  onCloseCreateEdit: Function;
  taskCodeId?: string;
}

const initialTaskCode: ITaskCode = {
  ResourceID: null,
  UniqueID: null,
  Name: null,
  Category: null,
  StandardCost: null,
  CompanyStructureID: null,
  OptionID: null,
  OptionName: null,
  AHRI: null,
  CoolingBTU: null,
  HeatingBTU: null,
  EER: null,
  OptionUniqueID: null,
  Components: null,
  customField: null,
  customPricing: null,
  OrganizationalUnitID: 0,
  ID: '',
  RequiresCustomPrice: null,
  BusinessUnitName: null,
  CategoryName: null,
  TaxCategory: null,
  AFUE: null,
  SEER: null,
  Series: null,
  Make: null,
  ConfigurationType: null,
  CoolingTonnage: null,
};

const SignupSchema = Yup.object().shape({
  ID: Yup.string().min(2, 'Too Short').max(1000, 'Too Long'),
  ResourceID: Yup.number().nullable(),
  UniqueID: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  Name: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  Category: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  StandardCost: Yup.number().nullable(),
  CompanyStructureID: Yup.number().nullable(),
  OptionID: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  OptionName: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  AHRI: Yup.number().nullable(),
  CoolingBTU: Yup.number().nullable(),
  HeatingBTU: Yup.number().nullable(),
  EER: Yup.number().nullable(),
  OptionUniqueID: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  customField: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  customPricing: Yup.number().nullable(),
  OrganizationalUnitID: Yup.number().required(),
  BusinessUnitName: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  CategoryName: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
  RequiresCustomPrice: Yup.number().nullable(),
  SEER: Yup.number().nullable(),
  AFUE: Yup.number().nullable(),
  ConfigurationType: Yup.number().nullable(),
  Series: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  Make: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').nullable(),
  CoolingTonnage: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .nullable(),
});

export const TaskCodeForm = ({
  rowData,
  mode,
  onCloseCreateEdit,
  taskCodeId,
}: ITaskCodeForm) => {
  const {
    taskCodeDrafts,
    getDraftByTaskCodeID,
    createTaskCodeDraft,
    updateTaskCodeDraft,
  } = useDraftGtc();

  const { userName, userADId } = useRole();
  const [draftExist, setDraftExist] = useState(false);
  const [taskCode, setTaskCode] = useState<ITaskCode>();
  let title;

  if (mode === ModalMode.ReadOnly) title = 'GTC & Components View';

  //initializer
  useEffect(() => {
    if (rowData) {
      setTaskCode(rowData);
      getDraftByTaskCodeID(rowData.ID);
    }
    if (taskCodeId) {
      getDraftByTaskCodeID(taskCodeId);
    }
  }, []);

  useEffect(() => {
    if (taskCodeDrafts[0]) {
      setDraftExist(true);
    }
    if (taskCodeId && !taskCode) {
      setTaskCode(taskCodeDrafts[0]);
    }
  }, [taskCodeDrafts]);

  const onSubmit = async (value: ITaskCode) => {
    if (mode === ModalMode.Create) {
    }
    if (mode === ModalMode.Update) {
      const taskCodeDraft: ITaskCodeDraft = {
        ...value,
        DBOperation: DbOperation.Update,
        ModificationUser: userName,
        ModificationUserId: userADId,
      };
      if (draftExist) {
        await updateTaskCodeDraft(taskCodeDraft);
        toast.success('GTC update draft updated');
      } else {
        await createTaskCodeDraft(taskCodeDraft);
        toast.success('GTC update draft created');
      }
    }
    onCloseCreateEdit();

    //TODO delete uploaded image to azure if taskcode save failed
  };

  const onClickClose = (event: any) => {
    event.preventDefault();
    onCloseCreateEdit();
  };

  const handleComponentdGridChange = (
    components: IComponent[],
    setValues: Function,
  ) => {
    setValues((preValues: ITaskCode) => ({
      ...preValues,
      Components: JSON.stringify(components),
    }));
  };

  const getChangedValues = (values: ITaskCode, initialValues: ITaskCode) => {
    return (Object.keys(values) as Array<keyof ITaskCode>).reduce<any>(
      (preview, key) => {
        if (initialValues[key] !== values[key]) {
          preview[key] = values[key];
        }
        return preview;
      },
      {},
    );
  };

  return (
    <>
      <div>
        <h1>{title}</h1>
      </div>
      {taskCode && (
        <Formik
          initialValues={taskCode}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            setValues,
            setFieldValue,
          }) => (
            <Form>
              <div className="form">
                <div className="components__field">
                  <ComponentsGrid
                    onBlur={handleBlur}
                    onChange={(components: IComponent[]) =>
                      handleComponentdGridChange(components, setValues)
                    }
                    value={
                      values.Components ? JSON.parse(values.Components) : []
                    }
                    taskCode={rowData?.ID}
                    branchID={rowData?.OrganizationalUnitID}
                  />
                </div>
                <div className="form__fields">
                  <div className="field__container">
                    <label>ID</label>

                    <Field
                      className="field"
                      id="UniqueID"
                      name="UniqueID"
                      placeholder="Unique ID"
                      value={values.ID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.ID && errors.ID && <div>{errors.ID}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Unique ID</label>

                    <Field
                      className="field"
                      id="UniqueID"
                      name="UniqueID"
                      placeholder="Unique ID"
                      value={values.UniqueID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.UniqueID && errors.UniqueID && (
                        <div>{errors.UniqueID}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Resource ID</label>

                    <Field
                      className="field"
                      id="ResourceID"
                      name="ResourceID"
                      placeholder="Resource ID"
                      value={values.ResourceID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.ResourceID && errors.ResourceID && (
                        <div>{errors.ResourceID}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Name</label>

                    <Field
                      className="field"
                      id="Name"
                      name="Name"
                      placeholder="Name"
                      value={values.Name ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.Name && errors.Name && <div>{errors.Name}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Category</label>

                    <Field
                      className="field"
                      id="Category"
                      name="Category"
                      placeholder="Category"
                      value={values.Category ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.Category && errors.Category && (
                        <div>{errors.Category}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Standard Cost</label>

                    <Field
                      className="field"
                      id="StandardCost"
                      name="StandardCost"
                      placeholder="Standard Cost"
                      value={values.StandardCost ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.StandardCost && errors.StandardCost && (
                        <div>{errors.StandardCost}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Company Structure ID</label>

                    <Field
                      className="field"
                      id="CompanyStructureID"
                      name="CompanyStructureID"
                      placeholder="Company Structure ID"
                      value={values.CompanyStructureID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.CompanyStructureID &&
                        errors.CompanyStructureID && (
                          <div>{errors.CompanyStructureID}</div>
                        )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Option ID</label>

                    <Field
                      className="field"
                      id="OptionID"
                      name="OptionID"
                      placeholder="Option ID"
                      value={values.OptionID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.OptionID && errors.OptionID && (
                        <div>{errors.OptionID}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Option Name</label>

                    <Field
                      className="field"
                      id="OptionName"
                      name="OptionName"
                      placeholder="Option Name"
                      value={values.OptionName ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.OptionName && errors.OptionName && (
                        <div>{errors.OptionName}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>AHRI</label>

                    <Field
                      className="field"
                      id="AHRI"
                      name="AHRI"
                      placeholder="AHRI"
                      value={values.AHRI ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.AHRI && errors.AHRI && <div>{errors.AHRI}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Cooling BTU</label>

                    <Field
                      className="field"
                      id="CoolingBTU"
                      name="CoolingBTU"
                      placeholder="Cooling BTU"
                      value={values.CoolingBTU ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.CoolingBTU && errors.CoolingBTU && (
                        <div>{errors.CoolingBTU}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Heating BTU</label>

                    <Field
                      className="field"
                      id="HeatingBTU"
                      name="HeatingBTU"
                      placeholder="Heating BTU"
                      value={values.HeatingBTU ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.HeatingBTU && errors.HeatingBTU && (
                        <div>{errors.HeatingBTU}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>EER</label>

                    <Field
                      className="field"
                      id="EER"
                      name="EER"
                      placeholder="EER"
                      value={values.EER ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.EER && errors.EER && <div>{errors.EER}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Option Unique ID</label>

                    <Field
                      className="field"
                      id="OptionUniqueID"
                      name="OptionUniqueID"
                      placeholder="Option Unique ID"
                      value={values.OptionUniqueID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.OptionUniqueID && errors.OptionUniqueID && (
                        <div>{errors.OptionUniqueID}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Custom Field</label>

                    <Field
                      className="field"
                      id="customField"
                      name="customField"
                      placeholder="Custom Field"
                      value={values.customField ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.customField && errors.customField && (
                        <div>{errors.customField}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Custom Pricing</label>

                    <Field
                      className="field"
                      id="customPricing"
                      name="customPricing"
                      placeholder="Custom Pricing"
                      value={values.customPricing ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.customPricing && errors.customPricing && (
                        <div>{errors.customPricing}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Organizational Unit ID</label>

                    <Field
                      className="field"
                      id="OrganizationalUnitID"
                      name="OrganizationalUnitID"
                      placeholder="Organizational Unit ID"
                      value={values.OrganizationalUnitID ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.OrganizationalUnitID &&
                        errors.OrganizationalUnitID && (
                          <div>{errors.OrganizationalUnitID}</div>
                        )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Business Unit Name</label>

                    <Field
                      className="field"
                      id="BusinessUnitName"
                      name="BusinessUnitName"
                      placeholder="Business Unit Name"
                      value={values.BusinessUnitName ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.BusinessUnitName && errors.BusinessUnitName && (
                        <div>{errors.BusinessUnitName}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Category Name</label>

                    <Field
                      className="field"
                      id="CategoryName"
                      name="CategoryName"
                      placeholder="Category Name"
                      value={values.CategoryName ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.CategoryName && errors.CategoryName && (
                        <div>{errors.CategoryName}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Requires Custom Price</label>

                    <Field
                      className="field"
                      id="RequiresCustomPrice"
                      name="RequiresCustomPrice"
                      placeholder="Requires Custom Price"
                      value={values.RequiresCustomPrice ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.RequiresCustomPrice &&
                        errors.RequiresCustomPrice && (
                          <div>{errors.RequiresCustomPrice}</div>
                        )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>SEER</label>

                    <Field
                      className="field"
                      id="SEER"
                      name="SEER"
                      placeholder="SEER"
                      value={values.SEER ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.SEER && errors.SEER && <div>{errors.SEER}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>AFUE</label>

                    <Field
                      className="field"
                      id="AFUE"
                      name="AFUE"
                      placeholder="AFUE"
                      value={values.AFUE ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.AFUE && errors.AFUE && <div>{errors.AFUE}</div>}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>ConfigurationType</label>

                    <Field
                      className="field"
                      id="ConfigurationType"
                      name="ConfigurationType"
                      placeholder="ConfigurationType"
                      value={values.ConfigurationType ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                      onChange={(e: any) =>
                        handleNumericChange(e, setFieldValue)
                      }
                    />
                    <small>
                      {touched.ConfigurationType &&
                        errors.ConfigurationType && (
                          <div>{errors.ConfigurationType}</div>
                        )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Series</label>

                    <Field
                      className="field"
                      id="Series"
                      name="Series"
                      placeholder="Series"
                      value={values.Series ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.Series && errors.Series && (
                        <div>{errors.Series}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>CoolingTonnage</label>

                    <Field
                      className="field"
                      id="CoolingTonnage"
                      name="CoolingTonnage"
                      placeholder="CoolingTonnage"
                      value={values.CoolingTonnage ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.CoolingTonnage && errors.CoolingTonnage && (
                        <div>{errors.CoolingTonnage}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__container">
                    <label>Make</label>

                    <Field
                      className="field"
                      id="Make"
                      name="Make"
                      placeholder="Make"
                      value={values.Make ?? ''}
                      disabled={mode === ModalMode.ReadOnly}
                    />
                    <small>
                      {touched.Make && errors.Make && <div>{errors.Make}</div>}
                    </small>
                  </div>
                </div>
              </div>
              <div className="buttons__actions">
                <Button
                  onClick={onClickClose}
                  variant="outlined"
                  className="button__cancel__gtc"
                >
                  Close
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
