import { Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ModalMode } from '../../../../config/enums';
import { DbOperation } from '../../../../enums/DbOperation';
import { handleNumericChange } from '../../../../helpers/helper';
import { IFinancingOffer } from '../../../../interfaces/financingOffer';
import { useDraftFinancing } from '../../../hooks/useDraftFinancing';
import { useRole } from '../../../hooks/useRole';
import './styles.scss';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { IFinancingOfferDraft } from '../../../../interfaces/financingDraft';

interface IFinancingOfferForm {
  mode: ModalMode;
  rowData?: IFinancingOffer | undefined;
  onCloseCreateEdit: Function;
  draftID?: string;
}

const initialFinancingOffer: IFinancingOffer = {
  ID: 0,
  LoanCode: '',
  LoanName: '',
  BankLoanName: '',
  PayFactor: null,
  Minimum: null,
  Maximum: null,
  ContractorFee: null,
  Interest: null,
  BranchID: null,
};

const patternTwoDigitsAfterComma = /^\d+(\.\d{0,20})?$/;
const commonDecimalValidator = Yup.number()
  .nullable()
  .test('is-decimal', (val: any) => {
    if (val !== undefined) {
      return patternTwoDigitsAfterComma.test(val);
    }
    return true;
  })
  .typeError('Incorrect Format (Only numbers and one comma)')
  .required();

const SignupSchema = Yup.object().shape({
  id: Yup.number(),
  LoanCode: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').required(),
  LoanName: Yup.string().min(2, 'Too Short').max(1000, 'Too Long').required(),
  BankLoanName: Yup.string()
    .min(2, 'Too Short')
    .max(1000, 'Too Long')
    .required(),
  PayFactor: commonDecimalValidator,
  Interest: commonDecimalValidator,
});

export const FinancingOfferForm = ({
  rowData = initialFinancingOffer,
  mode,
  onCloseCreateEdit,
  draftID,
}: IFinancingOfferForm) => {
  const {
    financingDrafts,
    createFinancingDraft,
    updateFinancingDraft,
    getDraftByFinancingID,
    getFinancingDraftByDraftID,
  } = useDraftFinancing();

  const { userName, userADId } = useRole();
  const [draftExist, setDraftExist] = useState(false);
  const [financingDraft, setFinancingDraft] = useState<IFinancingOfferDraft>();
  const { roleBranchOptions } = useRole();
  const [validateBranch, setValidateBranch] = useState(false);

  let title;
  if (mode === ModalMode.Create) title = 'Create Financing Offer';
  if (mode === ModalMode.Update) title = 'Edit Financing Offer';

  //initializer
  useEffect(() => {
    fetchDraft();
  }, []);

  useEffect(() => {
    if (
      roleBranchOptions.length > 0 &&
      mode === ModalMode.Update &&
      rowData.BranchID &&
      roleBranchOptions.some(
        branchOption => branchOption.value === rowData.BranchID?.toString(),
      )
    ) {
      setValidateBranch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleBranchOptions, mode, rowData.BranchID]);

  useEffect(() => {
    if (financingDrafts[0]) {
      setDraftExist(true);
    }
  }, [financingDrafts]);

  const fetchDraft = async () => {
    // Creating new Draft from financing
    if (mode === ModalMode.Create) {
      setFinancingDraft({
        ...initialFinancingOffer,
        DBOperation: DbOperation.Insert,
      });
    }
    // Updating fom Financing
    if (mode === ModalMode.Update && rowData && !draftID) {
      setFinancingDraft({ ...rowData, DBOperation: DbOperation.Update });
    }
    // Updating from CDX Updates
    if (draftID) {
      const { recordset } = await getFinancingDraftByDraftID(draftID);
      setFinancingDraft(recordset[0]);
    }
  };

  const handleBranchChanged = (branch: number, setValues: Function) => {
    setValidateBranch(true);
    setValues((prev: IFinancingOffer) => ({
      ...prev,
      BranchID: branch,
    }));
  };

  const onSubmit = async (value: IFinancingOfferDraft) => {
    if (validateBranch) {
      // Creating new Draft from Financing
      if (mode === ModalMode.Create) {
        const financingDraft: IFinancingOfferDraft = {
          ...value,
          ModificationUser: userName,
          ModificationUserId: userADId,
        };
        await createFinancingDraft(financingDraft);
        toast.success('Financing draft created');
      }

      // Updating fom Financing
      if (mode === ModalMode.Update && rowData && !draftID) {
        const financingDraft: IFinancingOfferDraft = {
          ...value,
          ModificationUser: userName,
          ModificationUserId: userADId,
        };
        const { recordset } = await getDraftByFinancingID(
          rowData.ID.toString(),
        );
        if (recordset[0]) {
          financingDraft.DraftID = recordset[0].DraftID;
          await updateFinancingDraft(financingDraft);
        } else {
          await createFinancingDraft(financingDraft);
        }
        toast.success('Financing draft updated');
      }
      // Updating from CDX Updates
      if (draftID) {
        const financingDraft: IFinancingOfferDraft = {
          ...value,
          ModificationUser: userName,
          ModificationUserId: userADId,
        };
        financingDraft.DraftID = draftID;
        await updateFinancingDraft(financingDraft);
        toast.success('Financing draft updated');
      }
      onCloseCreateEdit();
    } else {
      console.log('invalid branch');
    }
  };

  const onClickClose = (event: any) => {
    event.preventDefault();
    onCloseCreateEdit();
  };

  return (
    <>
      <div>
        <h1>{title}</h1>
      </div>
      {financingDraft && (
        <Formik
          initialValues={financingDraft}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            setValues,
            setFieldValue,
          }) => (
            <Form>
              <div className="form__financing">
                <div className="form__financing__fields">
                  {ModalMode.Update === mode && (
                    <div className="field__value">
                      <label>ID</label>

                      <Field
                        className="value__field__financing"
                        id="id"
                        name="id"
                        placeholder="id"
                        value={values.ID ?? ''}
                        disabled
                      />
                    </div>
                  )}
                  <div className="field__value">
                    <label>Loan Code(*)</label>
                    <Field
                      className="value__field__financing"
                      id="LoanCode"
                      name="LoanCode"
                      placeholder="Loan Code"
                      value={values.LoanCode ?? ''}
                    />
                    <small>
                      {touched.LoanCode && errors.LoanCode && (
                        <div className="error"> {errors.LoanCode}</div>
                      )}
                    </small>
                  </div>

                  <div className="field__value">
                    <label>Loan Name(*)</label>

                    <Field
                      className="value__field__financing"
                      id="LoanName"
                      name="LoanName"
                      placeholder="Loan Name"
                      value={values.LoanName ?? ''}
                    />
                    <small>
                      {touched.LoanName && errors.LoanName && (
                        <div className="error">{errors.LoanName}</div>
                      )}
                    </small>
                  </div>

                  <div className="field__value">
                    <label>Pay Factor</label>

                    <Field
                      className="value__field__financing"
                      id="PayFactor"
                      name="PayFactor"
                      placeholder="Pay Factor"
                      value={values.PayFactor ?? ''}
                    />
                    <small>
                      {touched.PayFactor && errors.PayFactor && (
                        <div className="error">{errors.PayFactor}</div>
                      )}
                    </small>
                  </div>

                  <div className="field__value">
                    <label>Interest</label>

                    <Field
                      className="value__field__financing"
                      id="Interest"
                      name="Interest"
                      placeholder="Interest"
                      value={values.Interest ?? ''}
                    />
                    <small>
                      {touched.Interest && errors.Interest && (
                        <div className="error">{errors.Interest}</div>
                      )}
                    </small>
                  </div>
                  <div className="field__value">
                    <label>Branch ID (*)</label>
                    {mode === 'create' && (
                      <div className="branch__drop__finan">
                        <Dropdown
                          onChange={(event: any) =>
                            handleBranchChanged(event, setValues)
                          }
                          options={roleBranchOptions}
                          value={Number(values.BranchID)}
                          label=""
                          type={1}
                        />
                      </div>
                    )}
                    {mode === 'update' && (
                      <>
                        <Field
                          className="value__field__financing"
                          id="BranchID"
                          name="BranchID"
                          placeholder="Branch ID"
                          value={values.BranchID ?? ''}
                          disabled
                        />
                        <small>
                          {touched.BranchID && errors.BranchID && (
                            <div className="error">{errors.BranchID}</div>
                          )}
                        </small>
                      </>
                    )}
                  </div>
                  <div className="field__value">
                    <label>Bank Loan Name(*)</label>

                    <Field
                      className="value__field__financing"
                      id="BankLoanName"
                      name="BankLoanName"
                      placeholder="Bank Loan Name"
                      value={values.BankLoanName ?? ''}
                    />
                    <small>
                      {touched.BankLoanName && errors.BankLoanName && (
                        <div className="error">{errors.BankLoanName}</div>
                      )}
                    </small>
                  </div>
                </div>
              </div>
              <div className="buttons__actions">
                <Button
                  onClick={onClickClose}
                  variant="outlined"
                  className="button__cancel"
                >
                  Cancel/Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="button__save__fin"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
